import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import App from "App";
import { availableRoutes } from "./navItems";

const Router = () => {
  const routes = useRecoilValue(availableRoutes);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />}>
          {routes.map(({ path, element, subRoutes }) =>
            subRoutes ? (
              <Route key={`${path}Route`} path={path} element={element}>
                {subRoutes.map(({ path, element }) => (
                  <Route key={`${path}Route`} path={path} element={element} />
                ))}
              </Route>
            ) : (
              <Route key={`${path}Route`} path={path} element={element} />
            )
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
