import PaymentOption from 'payments/components/PaymentOption';
import Tile from 'components/Tile';
import Flex from 'components/Flex';
import { useRecoilValue } from 'recoil';
import { incompleteRequestsState, paymentOptionsState } from 'payments/data';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import Text from 'components/Text';
import { formatCurrency } from 'util/helpers';
import { isEmpty } from 'lodash';
import { bankAccountsState } from 'bankAccounts/data';
import { selectedPaymentRequestState } from 'paymentRequests/data';

const loanOptionColors = [
    'var(--color-pastel-blue)',
    'var(--color-background-blue)',
    'var(--color-light-green)',
];

const PaymentOptions = () => {
    const navigate = useNavigate();
    const { paymentRequestId } = useParams();
    const paymentOptions = useRecoilValue(paymentOptionsState);
    const incompleteRequests = useRecoilValue(incompleteRequestsState);
    const selectedPaymentRequest = useRecoilValue(selectedPaymentRequestState);
    const bankAccountsList = useRecoilValue(bankAccountsState);
    const currPaymentRequest = useMemo(
        () => paymentRequestId
            ? incompleteRequests?.find(({ id }) => id === paymentRequestId) ?? {}
            : selectedPaymentRequest,
        [incompleteRequests, paymentRequestId, selectedPaymentRequest]
    );
    useEffect(() => {
        if (isEmpty(currPaymentRequest)) {
            navigate('../../payments');
        }
    }, [currPaymentRequest, navigate]);
    return (
        <Tile
            title={paymentRequestId ? 'Select Payment Option for New Request' : 'Select a Payment Option'}
        >
            {paymentRequestId && (
                <>
                    <Flex p="0 0 5px" gap="10" centerAlign>
                        <Text variant="p1" bold>Initial Amount:</Text>
                        <Text variant="p1">{formatCurrency(currPaymentRequest?.payment_amount)}</Text>
                    </Flex>
                    <Flex p="0 0 20px" gap="10" centerAlign>
                        <Text variant="p1" bold>Account Name:</Text>
                        <Text variant="p1">{bankAccountsList?.[0]?.meta?.institution?.name}</Text>
                    </Flex>
                </>
            )}
            <Flex gap="20">
                {paymentOptions.map((loanOption, index) => (
                    <PaymentOption
                        key={loanOption.id}
                        loanOption={loanOption}
                        color={loanOptionColors[index]}
                        paymentRequest={currPaymentRequest}
                    />
                ))}
            </Flex>
        </Tile>
    );
};

export default PaymentOptions;
