import styled from "@emotion/styled";
import Flex from "components/Flex";
import { incompleteRequestsState, paymentsAndRequestsState, paymentState, refreshPaymentsAndRequests } from "payments/data";
import { bankAccountsState } from "bankAccounts/data";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentUser, organizationUnderwritingCheckState } from "user/data";
import { useEffect } from 'react';
import { onboardingPaymentRequestState } from 'paymentRequests/data';

const FixedWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
`;

const OnboardingOutlet = () => (
  <FixedWrapper centered>
    <Outlet />
  </FixedWrapper>
);

const Onboarding = () => {
  const user = useRecoilValue(currentUser);
  const paymentsList = useRecoilValue(paymentState);
  const requestsList = useRecoilValue(incompleteRequestsState);
  const bankAccountsList = useRecoilValue(bankAccountsState);
  const organizationCheck = useRecoilValue(organizationUnderwritingCheckState);
  const onboardingRequest = useRecoilValue(onboardingPaymentRequestState);
  const setPaymentsAndRequests = useSetRecoilState(paymentsAndRequestsState);
  const { pathname } = useLocation();

  useEffect(() => {
    refreshPaymentsAndRequests(setPaymentsAndRequests);
  }, [setPaymentsAndRequests]);

  if (!user.first_name) {
    return pathname.includes("customer-details") ? (
      <OnboardingOutlet />
    ) : (
      <Navigate to="customer-details" />
    );
  }

  if (!user.organization.legal_name) {
    return pathname.includes("business-details") ? (
      <OnboardingOutlet />
    ) : (
      <Navigate to="business-details" />
    );
  }

  if (paymentsList.length) {
    return requestsList?.length ? (
      <Navigate to={`../new-request/${requestsList[0].id}`} />
    ) : (
      <Navigate to="../payments" />
    );
  }

  if (!bankAccountsList.length) {
    return pathname.includes("connect-plaid") ? (
      <OnboardingOutlet />
    ) : (
      <Navigate to="connect-plaid" />
    );
  }

  if (organizationCheck === null) {
    return pathname.includes("underwriting-check") ||
      pathname.includes("connect-plaid") ? (
      <OnboardingOutlet />
    ) : (
      <Navigate to="underwriting-check" />
    );
  }

  if (!organizationCheck) {
    return pathname.includes("application-denied") ? (
      <OnboardingOutlet />
    ) : (
      <Navigate to="application-denied" />
    );
  }

  if (organizationCheck) {
    return <Navigate to={`../new-request/${onboardingRequest.id}`}  />;
  }

  return <Navigate to="../payments" />;
};

export default Onboarding;
