import callApi from "api";
import { onboardingPaymentRequestState } from "paymentRequests/data";
import { TOKEN_STORAGE_KEY } from "common/constants";
import { ORGANIZATION_STATUS, ORGANIZATION_TYPE } from "common/enums";
import { atom, selector } from "recoil";
import { USER_ROUTES } from "./api";
import { paymentState } from "payments/data";
import { isEmpty } from "lodash";

const fetchCurrentUserEffect =
  () =>
  ({ setSelf, trigger, resetSelf }) => {
    // TODO: Handle this using recoil-sync
    if (window.location.pathname.split("/")[1] === "verify-user") {
      resetSelf();
      return;
    }
    if (trigger === "get") {
      setSelf(
        callApi(USER_ROUTES.ME)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              resetSelf();
              if (response.status === 403) {
                localStorage.removeItem(TOKEN_STORAGE_KEY);
                throw new Error("Token not valid");
              } else {
                throw new Error("Could not validate User");
              }
            }
          })
          .then((data) => {
            return data;
          })
          .catch((e) => {
            // TODO: Pop toast?
            console.error(e);
          })
      );
    }
  };

export const refreshUser = async (setUser) => {
  const response = await callApi(USER_ROUTES.ME);
  const user = await response.json();
  setUser(user);
};

export const currentUser = atom({
  key: "CurrentUser",
  default: { organization: {} },
  effects: [fetchCurrentUserEffect()],
});

export const userOrgType = selector({
  key: "UserOrgType",
  get: ({ get }) => {
    return get(currentUser)?.organization?.organization_type;
  },
});

export const orgPaymentTerms = selector({
  key: "OrgPaymentTerms",
  get: ({get}) => {
    return get(currentUser)?.organization?.default_payment_terms_days
  }
})

export const userState = selector({
  key: "userState",
  get: ({ get }) => {
    const currentOrgStatus = get(currentUser)?.organization?.status;
    switch (currentOrgStatus) {
      case ORGANIZATION_STATUS.PENDING:
        return "onboarding";
      case ORGANIZATION_STATUS.ACTIVE:
        return "active";
      default:
        return "unauthorized";
    }
  },
});

export const userActive = selector({
  key: "userActive",
  get: ({ get }) => {
    const { organization } = get(currentUser);
    if (isEmpty(organization)) {
      return false;
    }
    if (organization.organization_type === ORGANIZATION_TYPE.VENDOR) {
      return true;
    } else {
      const loanList = get(paymentState);
      return !!loanList.length;
    }
  },
});

export const userOnboardingState = atom({
  key: "UserOnboardingState",
  default: {
    hasVerifiedACH: false,
  },
});

export const organizationUnderwritingCheckState = atom({
  key: "OrganizationCheckState",
  default: null,
});

export const organizationUnderwritingCheckQuery = selector({
  key: "OrganizationCheckQuery",
  get: async ({ get }) => {
    const pr = get(onboardingPaymentRequestState);
    const currUser = get(currentUser);
    if (currUser?.organization?.id && pr?.id) {
      const response = await callApi(
        `/organizations/${currUser.organization.id}/underwriting-check?prid=${pr.id}`
      );
      if (response.ok) {
        const { underwriting_check } = await response.json();
        return underwriting_check;
      }
    }
    return null;
  },
  set: ({ set }, newValue) => set(organizationUnderwritingCheckState, newValue),
});
