const colors = {
  black: "#222222",
  blackSemiOpaque: "#222222bb",
  blackLowOpacity: "#22222226",
  blackOneThirdOpacity: "#22222254",
  blackOneQuarterOpacity: "#22222240",
  darkGray: "#607180",
  mediumDarkGray: "#8895a5",
  mediumLightGray: "#bdbdbd",
  lightGray: "#e0e0e0",
  iceWhite: "#f5f7f8",
  blue: "#385df7",
  lightBlue: "#788cfc",
  pastelBlue: "#e3e6ff",
  purple: "#7538f7",
  red: "#cc444b",
  lightRed: "#ffecec",
  snowWhite: "#f2f4fa",
  green: "#1fa866",
  lightGreen: "#dbffe0",
  pastelGreen: "#b7fdc1",
  white: "#ffffff",
};

export default colors;
