import styled from '@emotion/styled';
import Flex from 'components/Flex';
import Text from 'components/Text';

const BoldText = styled.span`
    font-weight: 700;
`;

const TermsSection = styled(Flex)`
    flex-direction: column;
    padding-left: 20px;
    margin: 10px 0 0;
    & li:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

const PaymentTermsAgreement = () => {
    return (
        <>
            <Text variant="h2" p="20px 0 10px" centered>AGREEMENT</Text>
            <Text variant="p2">
                This EXTENDED PAYMENT TERMS AGREEMENT (as amended, restated, amended
                and restated, supplemented or otherwise modified from time to time,
                this “<BoldText>Agreement</BoldText>”), dated as of the Effective Date, is between TRUST
                SOFTWARE, INC., a Delaware corporation (“<BoldText>Company</BoldText>”), and CUSTOMER
                (together with Company, the “<BoldText>Parties</BoldText>”).  Capitalized terms used
                herein without definition shall have the meanings assigned to them
                in the Terms and Conditions (as defined below).
            </Text>
            <Text m="10px 0 0" variant="p2">
                <BoldText>WHEREAS</BoldText>, Customer is an obligor under that certain invoice, dated as
                of the Invoice Date, for a total payment amount of the Invoiced
                Amount, due to Vendor (the “<BoldText>Invoice</BoldText>”); and
            </Text>
            <Text m="10px 0 0" variant="p2">
                <BoldText>WHEREAS</BoldText>, subject to the terms and conditions of this Agreement and
                the Terms and Conditions (linked here) (the “<BoldText>Terms and Conditions</BoldText>”),
                Company has agreed to satisfy the Customer&apos;s payment obligations
                under the Invoice for the entire Invoiced Amount in exchange for a
                portion of the Customer&apos;s future revenue (such future revenues, the
                “<BoldText>Purchased Receivable</BoldText>”).
            </Text>
            <Text m="10px 0 0" variant="p2">
                <BoldText>NOW THEREFORE</BoldText>, for good and valuable consideration, the sufficiency
                of which is hereby acknowledged, the Parties agree as follows:
            </Text>
            <TermsSection>
                <Text variant="h3">Section 1. Purchased Receivable and Purchase Price</Text>
                <ol type="a">
                    <li>
                        <Text variant="p2">
                            As of the Effective Date, Customer hereby agrees to sell
                            and assign and hereby sells and transfers to Company,
                            and Company hereby agrees to the purchase and transfer
                            of, the Purchased Receivable as consideration for the
                            payment of the Extended Payment Amount, subject to the
                            terms and conditions of this Agreement.
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Notwithstanding anything to the contrary herein,
                            Company, in its sole and absolute discretion, may accept
                            or reject any proposed sale of a Purchased Receivable
                            submitted by Customer and shall notify Customer in
                            writing accordingly. Company shall not be under any
                            obligation to purchase the Purchased Receivable unless
                            Company has expressly accepted and identified it as a
                            Purchased Receivable in writing, and such acceptance has
                            not been withdrawn.
                        </Text>
                    </li>
                </ol>
            </TermsSection>
            <TermsSection>
                <Text variant="h3">Section 2. Payments to Vendor</Text>
                <ol type="a">
                    <li>
                        <Text variant="p2">
                            Customer agrees that all information provided as of the
                            Effective Date shall be sufficient information for
                            Company to process payment of the Invoiced Amount to
                            Vendor on the Vendor Payment Date. Company may
                            reasonably request additional information with respect
                            to such payment, and Customer agrees that it shall
                            provide such requested information no less than ten (10)
                            Business Days prior to the Vendor Payment Date and
                            acknowledges that it may take Company several Business
                            Days following Company&apos;s receipt of such Vendor
                            information to set up such payment to Vendor.
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Customer authorizes and instructs Company to follow all
                            payment instructions that Company receives from Customer
                            or Vendor to pay to Vendor the Invoiced Amount. Customer
                            agrees that Company may notify Vendor that the Invoice
                            has been transferred to Company and the payment of the
                            Invoiced Amount will be sent by Company to Vendor in
                            respect of Customer&apos;s obligation to pay the Invoiced
                            Amount on the Vendor Payment Date.
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Customer agrees that it shall be solely responsible for
                            selecting the appropriate Vendor Payment Date with
                            respect to any Invoice and, except as otherwise required
                            by law, for any penalties, interest charges, expenses
                            and other late payment fees related to or arising from
                            any failure by Company to pay the Invoiced Amount on the
                            appropriate Vendor Payment Date. Company does not
                            guarantee that the payment to Vendor will be made on the
                            Vendor Payment Date.
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Customer shall notify Vendor that Company has acquired
                            the Purchased Receivable and agrees and acknowledges
                            that any payment of the Invoiced Amount sent by or on
                            behalf of Company to Vendor shall satisfy Company&apos;s
                            obligations to Customer under this Section 2, regardless
                            of whether Vendor accepts such payment.
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            To stop a payment to Vendor after it has begun
                            processing, Customer must submit written notice to
                            Company through Customer&apos;s account. Company shall use
                            commercially reasonable efforts to stop such payment but
                            shall not be obligated to do so. Company shall have no
                            liability for failing to stop a payment that has begun
                            to process. Stopping a payment may be subject to a fee.
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Company retains the right to expire, void or cancel any
                            payment if not deposited or otherwise received and
                            processed by Vendor within a reasonable amount of time,
                            as determined by Company in its sole discretion. Such
                            payment shall be credited to Customer&apos;s account, minus
                            any applicable fees or other amounts owed to Company by
                            Customer.
                        </Text>
                    </li>
                </ol>
            </TermsSection>
            <TermsSection>
                <Text variant="h3">Section 3. Extended Payment Amount</Text>
                <ol type="a">
                    <li>
                        <Text variant="p2">
                            Customer agrees to pay to Company, and authorizes
                            Company to debit from the Counterparty Account, an
                            amount equal to the (i) Invoiced Amount plus (ii) the
                            product of (x) the Invoiced Amount and (y) the Extension
                            Fee (the “<BoldText>Extended Payment Amount</BoldText>”, as the same is set
                            forth within the Summary of Terms) on the Extended
                            Payment Due Date, which date shall not in any event be
                            later than the expiration of the Payment Extension
                            Period after the Vendor Payment Date.
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Customer agrees that if the Extended Payment Amount is
                            not paid on the Extended Payment Due Date, Customer
                            shall pay an additional amount equal to one-tenth of one
                            percent (0.10%) of the Extended Payment Amount for each
                            day that the Extended Payment Amount remains unpaid;
                            provided that Company may, in its sole discretion, waive
                            such fee in writing.
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Until the Extended Payment Amount is paid by Customer to
                            Company on the Extended Payment Due Date, Customer shall
                            hold such payment or other collection in trust for
                            Company (as Company&apos;s property) and Customer shall
                            otherwise designate that such Extended Payment Amount is
                            the sole property of Company, including, without
                            limitation, on its balance sheet and other financial
                            statements.
                        </Text>
                    </li>
                </ol>
            </TermsSection>
            <TermsSection>
                <Text variant="h3">Section 4. Representations and Warranties</Text>
                <Text>Seller represents and warrants that:</Text>
                <ol type="a">
                    <li>
                        <Text variant="p2">
                            The information Customer provided about Vendor is
                            complete and current and accurately identifies Vendor
                            and Vendor&apos;s payment information;
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            The Invoice is (i) free of any dispute, deduction,
                            setoff, defense, claim or counterclaim of any kind by
                            Customer against Vendor relating to the goods or
                            services provided by Vendor to such Customer, the
                            provision of which gave rise to the Purchased Receivable
                            and/or Customer&apos;s obligation to pay all or any portion
                            of the Purchased Receivable and (ii) issued pursuant to
                            a contractual relationship with Vendor for goods or
                            services completed and fulfilled by Vendor to Customer;
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Customer has the right to assign and transfer to Company
                            the Purchased Receivables, free and clear of any
                            transfer restrictions;
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Customer has obtained all required authorizations or
                            consents from Vendor to share Vendor&apos;s information with
                            Company. Customer is solely responsible for verifying the
                            accuracy of Vendor information provided by Customer in
                            connection with the Purchased Receivable and Invoice; and
                        </Text>
                    </li>
                    <li>
                        <Text variant="p2">
                            Customer has obtained all necessary approvals and
                            authorizations from Vendor to initiate the Vendor
                            Payments directly to Vendor.
                        </Text>
                    </li>
                </ol>
            </TermsSection>
            <TermsSection>
                <Text variant="h3">
                    Section 5. Integration of Terms and Conditions
                </Text>
                <Text variant="p2">
                    Customer expressly understands and acknowledges that the Terms
                    and Conditions are incorporated herein by reference, deemed a
                    part of this Agreement and are binding and enforceable
                    provisions of this Agreement. References to “this Agreement” or
                    the use of the term “herein” shall refer to this Agreement and
                    the Terms and Conditions, taken as a whole.
                </Text>
            </TermsSection>
        </>
    );
};

export default PaymentTermsAgreement;
