import styled from "@emotion/styled";
import Papa from "papaparse";
import callApi from "api";
import { useDropzone } from "react-dropzone";
import { errorToast } from "common/toasts";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { currentUser, refreshUser } from "user/data";
import Flex from "./Flex";
import Button from "./Button";
import Text from "./Text";
import { useForm } from "react-hook-form";
import fileIcon from "assets/icons/file.svg";
import deleteIcon from "assets/icons/circleX.svg";
import Select from "./Select";
import { CREATE_CUSTOMER_ERROR_MESSAGES } from "common/constants";
import arrowIcon from "assets/icons/right.svg";
import { AnimatePresence, motion } from "framer-motion";
import dayjs from "dayjs";

const IncompleteCustomerListText = styled(Text)`
  width: 100%;
  position: relative;
  cursor: pointer;
  background-color: var(--color-ice-white);
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 7px;
  &:hover {
    background-color: var(--color-light-gray);
  }
`;

const IncompleteCustomerListExpanded = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
  & > div {
    padding: 10px 0;
  }
  & > div:not(:last-of-type) {
    border-bottom: 1px solid var(--color-light-gray);
  }
`;

const ArrowIcon = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  transition: all 0.3s ease;
  transform: rotate(${({ expanded }) => (expanded ? 90 : 0)}deg);
`;

const CSVUploaderWrapper = styled(Flex)`
  min-width: 500px;
  gap: 20px;
`;

const DropzoneSection = styled(Flex)`
  width: 400px;
  border: 1px solid var(--color-black);
  text-align: center;
  padding: 20px 50px;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid transparent;
    background: var(--color-light-gray);
  }
`;

const SelectedFileDisplay = styled(Flex)`
  border: 1px solid var(--color-black);
  border-radius: 7px;
  padding: 20px;
  width: 150px;
  text-align: center;
  position: relative;
  & > div {
    width: 100%;
    word-wrap: break-word;
  }
`;

const FileIcon = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
`;

const DeleteButton = styled.img`
  width: 25px;
  height: 25px;
  object-fit: cover;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--color-white);
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
`;

const ColumnSelectRow = styled(Flex)`
  width: 400px;
  padding: 20px 0;
  justify-content: space-between;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-medium-light-gray);
  }
`;

const GreenText = styled(Text)`
  color: var(--color-green);
`;

const RedText = styled(Text)`
  color: var(--color-red);
`;

const IncompleteCustomersList = ({ list = [], message }) => {
  const [expanded, setExpanded] = useState(false);
  if (list.length) {
    return (
      <>
        <IncompleteCustomerListText onClick={() => setExpanded(!expanded)} bold>
          {message}
          <ArrowIcon src={arrowIcon} alt="arrow icon" expanded={expanded} />
        </IncompleteCustomerListText>
        <AnimatePresence>
          {expanded ? (
            <IncompleteCustomerListExpanded
              key="incomplete-customer-list-expanded"
              initial={{ height: 0 }}
              animate={{
                height: "fit-content",
                transition: {
                  duration: 0.2,
                },
              }}
              exit={{
                height: 0,
                transition: {
                  duration: 0.2,
                },
              }}
            >
              {list.map((email, index) => (
                <Text key={`${email}${index}`}>{email}</Text>
              ))}
            </IncompleteCustomerListExpanded>
          ) : (
            <></>
          )}
        </AnimatePresence>
      </>
    );
  }
  return null;
};

const CSVUploader = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const setCurrUser = useSetRecoilState(currentUser);
  const currentOrganization = useRecoilValue(currentUser).organization;
  const [step, setStep] = useState("upload");
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [reviewData, setReviewData] = useState({});
  const handleParse = (file) => {
    if (!file) return errorToast("Enter a valid file");
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      setHeaders(Object.keys(parsedData[0]));
      setData(parsedData);
      setSelectedFilename(file.name);
    };
    reader.readAsText(file);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [],
    },
    onDrop: (acceptedFiles) => {
      handleParse(acceptedFiles[0]);
    },
  });

  const resetFileData = useCallback(() => {
    setData([]);
    setHeaders([]);
    setSelectedFilename("");
  }, [setData, setHeaders, setSelectedFilename]);

  const parseMultiCustomerErrors = (incompleteCustomers = []) => {
    const customersByError = incompleteCustomers.reduce(
      (currIncompleteState, incompleteCustomer) => {
        const alreadyConnected = currIncompleteState.alreadyConnected;
        const connectedToAnotherVendor =
          currIncompleteState.connectedToAnotherVendor;
        const notACustomer = currIncompleteState.notACustomer;
        if (
          incompleteCustomer.detail ===
          CREATE_CUSTOMER_ERROR_MESSAGES.ALREADY_CONNECTED
        ) {
          alreadyConnected.push(incompleteCustomer.invite.customer_email);
        } else if (
          incompleteCustomer.detail ===
          CREATE_CUSTOMER_ERROR_MESSAGES.CONNECTED_TO_ANOTHER_VENDOR
        ) {
          connectedToAnotherVendor.push(
            incompleteCustomer.invite.customer_email
          );
        } else if (
          incompleteCustomer.detail ===
          CREATE_CUSTOMER_ERROR_MESSAGES.NOT_A_CUSTOMER
        ) {
          notACustomer.push(incompleteCustomer.invite.customer_email);
        }
        return {
          alreadyConnected,
          connectedToAnotherVendor,
          notACustomer,
        };
      },
      { alreadyConnected: [], connectedToAnotherVendor: [], notACustomer: [] }
    );
    return {
      customersByError,
      length: incompleteCustomers.length,
    };
  };

  const submitData = useCallback(
    async ({ customer_name, customer_email, invoice_date, payment_amount }) => {
      const formattedData = data.map((datum) => {
        const customerData = {
          customer_name: datum[customer_name],
          customer_email: datum[customer_email],
        };
        if (datum[invoice_date] && datum[payment_amount]) {
          customerData.invoice_date = invoice_date;
          customerData.payment_amount = parseFloat(datum[payment_amount]) * 100;
          customerData.payment_due_date = dayjs(invoice_date).add(
            currentOrganization.default_payment_terms_days,
            "day"
          );
        }
        return customerData;
      });
      const response = await callApi(
        "/organizations/customer",
        "POST",
        formattedData
      );
      const res = await response.json();
      if (response.ok) {
        setReviewData({
          completed: res?.completed?.length,
          incomplete: parseMultiCustomerErrors(res.incomplete),
        });
        setStep("review");
      } else {
        errorToast(
          res?.detail ??
            "There was an error parsing your CSV file. Please try again later."
        );
      }
    },
    [data, currentOrganization]
  );

  const ColumnDropdown = useCallback(
    ({ title, name, required = false }) => (
      <ColumnSelectRow centerAlign>
        <Flex column>
          <Text variant="h3">{title}</Text>
          {!required && (
            <Text variant="p2" secondary>
              (optional)
            </Text>
          )}
        </Flex>
        <Select
          values={headers}
          inputRef={() => register(name, { required })}
          handleClick={(val) => setValue(name, val)}
          defaultValue={headers.includes(name) ? name : ""}
          placeholder="Please select a column"
          errors={errors}
          size="medium"
        />
      </ColumnSelectRow>
    ),
    [headers, register, setValue, errors]
  );

  if (step === "upload") {
    return (
      <CSVUploaderWrapper column centered>
        <Text variant="h2">Upload Customer CSV File</Text>
        {selectedFilename ? (
          <SelectedFileDisplay gap="10" column centered>
            <DeleteButton
              src={deleteIcon}
              alt="delete icon"
              onClick={() => resetFileData()}
            />
            <FileIcon src={fileIcon} alt="file icon" />
            <Text>{selectedFilename}</Text>
          </SelectedFileDisplay>
        ) : (
          <DropzoneSection className="container" column>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Text>
                Drag and drop a customer CSV file or click to open file manager
              </Text>
            </div>
          </DropzoneSection>
        )}
        <Button
          size="full"
          onClick={() => setStep("map")}
          disabled={!selectedFilename}
        >
          Next
        </Button>
      </CSVUploaderWrapper>
    );
  }
  if (step === "map") {
    return (
      <CSVUploaderWrapper column centered>
        <Text variant="h2">Map CSV File Columns</Text>
        <form onSubmit={handleSubmit(submitData)}>
          <ColumnDropdown title="Customer Name" name="customer_name" required />
          <ColumnDropdown
            title="Customer Email"
            name="customer_email"
            required
          />
          <ColumnDropdown title="Invoice Date" name="invoice_date" />
          <ColumnDropdown title="Payment Amount" name="payment_amount" />
          <Button size="full" type="submit">
            Submit
          </Button>
        </form>
      </CSVUploaderWrapper>
    );
  }
  return (
    <CSVUploaderWrapper column>
      <Text variant="h1">Review Upload</Text>
      <GreenText variant="h3">
        {reviewData?.completed} customers succesfully created
      </GreenText>
      <Flex column>
        <RedText variant="h3">
          Failed to create {reviewData?.incomplete?.length} customers
        </RedText>
        <IncompleteCustomersList
          list={reviewData?.incomplete?.customersByError?.alreadyConnected}
          message={`${reviewData?.incomplete?.customersByError?.alreadyConnected.length} customers
                        are already connected.`}
        />
        <IncompleteCustomersList
          list={
            reviewData?.incomplete?.customersByError?.connectedToAnotherVendor
          }
          message={`${reviewData?.incomplete?.customersByError?.connectedToAnotherVendor.length} customers
                        are connected to another vendor.`}
        />
        <IncompleteCustomersList
          list={reviewData?.incomplete?.customersByError?.notACustomer}
          message={`${reviewData?.incomplete?.customersByError?.notACustomer.length} organizations
                        are not customers.`}
        />
      </Flex>
      <Button
        size="full"
        onClick={() => {
          refreshUser(setCurrUser);
          navigate("../");
        }}
      >
        Done
      </Button>
    </CSVUploaderWrapper>
  );
};

export default CSVUploader;
