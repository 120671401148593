import callApi from "api";
import { TOKEN_STORAGE_KEY } from "common/constants";
import { errorToast } from "common/toasts";
import Loader from "components/Loader";
import Tile from "components/Tile";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { currentUser } from "user/data";
import { paymentRequestState } from "paymentRequests/data";

const VerifyUser = () => {
  const { token } = useParams();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const setUser = useSetRecoilState(currentUser);
  const resetUser = useResetRecoilState(currentUser);
  const setPaymentRequestId = useSetRecoilState(paymentRequestState);
  useEffect(() => {
    const getToken = async () => {
      localStorage.clear();
      resetUser();
      const response = await callApi(`/users/verify-token?token=${token}`);
      if (response.ok) {
        const { access_token, user } = await response.json();
        localStorage.setItem(TOKEN_STORAGE_KEY, access_token);
        setUser(user);
        setPaymentRequestId({ id: search.get("prid") });
        localStorage.setItem("OnboardingPRID", search.get("prid"));
        navigate("../../onboarding/");
      } else {
        errorToast("There was an error when verifying user.");
      }
    };
    if (token) {
      getToken();
    }
  }, [token, search, setPaymentRequestId, setUser, navigate, resetUser]);
  return (
    <Tile title="Verifying...">
      <Loader />
    </Tile>
  );
};

export default VerifyUser;
