import { useState, useCallback } from "react";
import Button from "components/Button";
import styled from "@emotion/styled";
import callApi from "api";
import { errorToast, successToast } from "common/toasts";

const StatusButton = styled(Button)`
  :disabled {
    color: black;
    border-color: transparent;
    background-color: ${({ status }) =>
      status === "success"
        ? "var(--color-pastel-blue)"
        : "var(--color-light-red)"};
  }
`;

const ReinviteButton = ({ customerId, view }) => {
  const [inviteStatus, setInviteStatus] = useState("waiting");
  const sendInvite = useCallback(async () => {
    setInviteStatus("sending");
    const response = await callApi(
      `/organizations/customer/${customerId}/re-invite`
    );
    if (response.ok) {
      const message = await response.json();
      console.log(message);
      setInviteStatus("success");
      successToast(message);
    } else {
      errorToast();
      setInviteStatus("error");
    }
  }, [customerId]);
  return (
    <StatusButton
      status={inviteStatus}
      size={view === "payment" ? "full" : "small"}
      loading={inviteStatus === "sending"}
      disabled={inviteStatus === "success" || inviteStatus === "error"}
      onClick={sendInvite}
    >
      {inviteStatus === "waiting" && "Resend Invite"}
      {inviteStatus === "success" &&
        (view === "payment"
          ? "Your invite has been resent."
          : "Invite resent.")}
      {inviteStatus === "error" && "Unable to re-invite."}
    </StatusButton>
  );
};

export default ReinviteButton;
