import React from "react";
import Card from "./Card";
import Text from "./Text";
import redXOutline from "assets/red-x-outline-icon.svg";
import Flex from "./Flex";
import styled from "@emotion/styled";
import Logo from "./Logo";

const SpacedCard = styled(Card)`
  margin-top: 24px;
`;

const SpacedFlex = styled(Flex)`
  margin: 40px 32px;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Flex column>
          <SpacedFlex>
            <Logo />
          </SpacedFlex>
          <Flex centered>
            <SpacedCard column centered>
              <img src={redXOutline} alt="Red X" />
              <Text variant="h1" m="12px 0 24px 0" color="var(--color-red)">
                Something went wrong
              </Text>
              <Text variant="p1" secondary>
                Please refresh to try again, if issues persist please reach out
                to support@trust.co.
              </Text>
            </SpacedCard>
          </Flex>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
