import styled from "@emotion/styled";
import PropTypes from "prop-types";
import logo from "assets/logo.svg";

const SizedLogo = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const Logo = ({ width = "auto", height = "auto", ...rest }) => (
  <SizedLogo
    {...rest}
    width={width}
    height={height}
    src={logo}
    alt="Trust Logo"
  />
);

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Logo;
