import React from "react";
import { RecoilRoot } from "recoil";
import { createRoot } from "react-dom/client";
import Router from "./routing/Router";
import Loader from "components/Loader";
import ErrorBoundary from "components/ErrorBoundary";
import GlobalStyles from "components/GlobalStyles";
import Flex from "components/Flex";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

if (process.env?.REACT_APP_MARKET_ENV === "production") {
  Sentry.init({
    dsn: "https://f2a4e86854b948359097bf1d32e7f4e9@o212488.ingest.sentry.io/4503898304675840",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["api.getmarket.com"],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env?.REACT_APP_MARKET_ENV,
  });
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <RecoilRoot>
      <ErrorBoundary>
        <React.Suspense
          fallback={
            <Flex p="48px" centered>
              <Loader />
            </Flex>
          }
        >
          <Router />
        </React.Suspense>
      </ErrorBoundary>
    </RecoilRoot>
  </React.StrictMode>
);
