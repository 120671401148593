import styled from "@emotion/styled";
import { useCallback, useMemo } from "react";
import Flex from "components/Flex";
import Text from "components/Text";
import { formatCurrency } from "util/helpers";
import dayjs from "dayjs";
import chevronIcon from "assets/icons/right.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentUser } from "user/data";
import { paymentStatusMap } from "common/constants";
import { PAYMENT_STATUS } from "common/enums";

const PaymentWrapper = styled(Flex)`
  padding: 19px 50px 19px 20px;
  position: relative;
  border-top: 1px solid var(--color-medium-light-gray);
  cursor: pointer;
  transition: all 0.3s ease;
  :hover {
    background-color: var(--color-snow-white);
  }
`;

const RightChevron = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 32px;
  right: 10px;
`;

const FlexEnd = styled(Flex)`
  align-items: flex-end;
`

const Payment = ({ payment }) => {
  const navigate = useNavigate();
  const currUser = useRecoilValue(currentUser);
  const {
    id,
    payment_amount = 0,
    payment_due_date,
    status,
    updated_at,
  } = useMemo(() => payment ?? {}, [payment]);
  const vendorName = useMemo(
    () =>
      currUser.organization.vendor?.[0]?.legal_name ||
      currUser.organization.vendor?.[0]?.name,
    [currUser]
  );
  const getStatusColor = useCallback((status) => {
    if (status === PAYMENT_STATUS.COMPLETE) return "var(--color-green)";
    return status > 2 ? "var(--color-red)" : "var(--color-dark-gray)";
  }, []);
  return (
    <PaymentWrapper
      onClick={() => navigate(`${id}`)}
      justify="space-between"
      align="center"
    >
      <Flex gap="5" column>
        <Text variant="h3">{vendorName}</Text>
        <Flex column>
          <Text variant="p1" bold color={getStatusColor(status)}>
            {paymentStatusMap[status]}
          </Text>
          <Text variant="d" color="var(--color-medium-dark-gray)">updated at {dayjs(updated_at).format("lll")}</Text>
        </Flex>
      </Flex>
      <FlexEnd gap="5" column>
        <Text variant="h3">{formatCurrency(payment_amount)}</Text>
        <Text variant="p1">
          Payment Due {dayjs(payment_due_date).format("MM/DD/YYYY")}
        </Text>
      </FlexEnd>
      <RightChevron src={chevronIcon} alt="Right arrow" />
    </PaymentWrapper>
  );
};

export default Payment;
