import { useMemo } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { ROUTES } from "routing/navItems";
import Text from "./Text";
import Flex from "./Flex";
import { useRecoilValue } from "recoil";
import { currentUser } from "user/data";

const HeaderWrap = styled(Flex)`
  margin: 20px 0;
  padding: 0px 4px;
  align-items: center;
  justify-content: space-between;
`;

const UserInfo = styled.div`
  margin-left: 34px;
  padding-top: 4px;
`;

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const user = useRecoilValue(currentUser);
  const title = useMemo(() => {
    let currNavItem = Object.keys(ROUTES).find((item) =>
      pathname.includes(ROUTES[item].path)
    );
    return <Text variant="h1">{ROUTES[currNavItem]?.label}</Text>;
  }, [pathname]);
  return (
    <HeaderWrap>
      {title}
      <UserInfo>
        {user.first_name} {user.last_name}
      </UserInfo>
    </HeaderWrap>
  );
};

export default Header;
