import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import Table from "components/Table";
import { Link } from "react-router-dom";
import Button from "components/Button";
import chevronIcon from "assets/icons/right.svg";
import cardIcon from "assets/icons/card.svg";
import { paymentRequestsState } from "paymentRequests/data";
import PageSection from "components/PageSection";
import Flex from "components/Flex";
import Text from "components/Text";
import { downloadBlob, formatCurrency } from "util/helpers";
import dayjs from "dayjs";
import { useCallback, useState } from 'react';
import callApi from 'api';
import { PAYMENT_REQUEST_STATUS } from 'common/enums';

const RightChevron = styled.img`
  width: 20px;
  height: 20px;
`;

const CardIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const StatusCell = styled(Flex)`
  width: 110px;
  border-radius: 20px;
  padding: 5px 10px;
  color: ${({ bgColor }) => bgColor};
  font-weight: 700;
`

const paymentRequestStatusColorMap = {
  "Pending": "var(--color-dark-gray)",
  "In Progress": "var(--color-light-blue)",
  "ACH Initiated": "var(--color-green)",
  "Denied": "var(--color-red)",
}

const paymentRequestsColumns = [
  {
    header: "Business Name",
    id: "business_name",
    accessorFn: (row) => row?.customer?.name,
  },
  {
    header: "Amount",
    id: "amount",
    accessorFn: (row) => formatCurrency(row.payment_amount),
  },
  {
    header: "Created On",
    id: "created_on",
    accessorFn: (row) => dayjs(row.created_at).format("MM/DD/YYYY"),
  },
  {
    header: "Due Date",
    id: "due_date",
    accessorFn: (row) => dayjs(row.payment_due_date).format("MM/DD/YYYY"),
  },
  {
    header: "Paid Date",
    accessorFn: (row) =>
      row.payment_request_status?.ach_initiated
        ? dayjs(row.payment_request_status?.ach_initiated).format("MM/DD/YYYY")
        : 'N/A',
  },
  {
    header: "Status",
    accessorFn: (row) => {
      if (row.status === PAYMENT_REQUEST_STATUS.DENIED) return "Denied";
      const { invoice_sent, customer_onboarded, ach_initiated } =
        row.payment_request_status;
      if (ach_initiated) return "ACH Initiated";
      if (customer_onboarded) return "In Progress";
      if (invoice_sent) return "Pending";
    },
    cell: ({ getValue }) => (
      <StatusCell bgColor={paymentRequestStatusColorMap[getValue()]} centered>
        {getValue()}
      </StatusCell>
    )
  },
  {
    header: null,
    id: "details_link",
    cell: ({ row }) => {
      return (
        <Link
          to={`/customers/${row.original.customer_id}/payment-request/${row.original.id}/details`}
        >
          <RightChevron src={chevronIcon} />
        </Link>
      );
    },
  },
];
const PaymentRequests = () => {
  const [exporting, setExporting] = useState(false);
  const paymentRequests = useRecoilValue(paymentRequestsState);
  const handleClick = useCallback(async () => {
    setExporting(true)
    callApi(
      `/payment-request/export`
    ).then(async (res) => {
      const blob = await res.blob()
      downloadBlob(blob, 'PaymentRequests.csv')
      setExporting(false)
    })
  }, [setExporting])
  return (
    <PageSection
      title="All Payment Requests"
      controls={
        <Button size="small" onClick={handleClick} loading={exporting}>
          Export as CSV
        </Button>
      }
      tableSection
    >
      {paymentRequests?.length ? (
        <Table columns={paymentRequestsColumns} data={paymentRequests} />
      ) : (
        <Flex gap="10" p="20px" column centered>
          <CardIcon src={cardIcon} alt="User icon" />
          <Text variant="h2">
            No Payment Requests, invite a customer to get started
          </Text>
          <Link to="/customers/create">
            <Button size="medium">Create Customer</Button>
          </Link>
        </Flex>
      )}
    </PageSection>
  );
};

export default PaymentRequests;
