import { Outlet } from "react-router-dom";
import "./App.css";
import Layout from "components/Layout.js";
import React from "react";
import Loader from "components/Loader";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);

function App() {
  return (
    <Layout>
      <React.Suspense fallback={<Loader />}>
        <Outlet />
      </React.Suspense>
    </Layout>
  );
}

export default App;
