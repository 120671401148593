import callApi from 'api';
import { atom, selector } from 'recoil';

export const termsAcceptancePaymentRequestIdState = atom({
  key: "TermsAcceptancePaymentRequestIdState",
  default: null,
});

export const selectedTermsAcceptanceObjectState = selector({
  key: "SelectedTermsAcceptanceObjectState",
  get: async ({ get }) => {
    const prId = get(termsAcceptancePaymentRequestIdState);
    if (prId) {
      const response = await callApi(
        `/terms-acceptance/${prId}`
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    }
    return {};
  },
});