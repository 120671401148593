import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isURL } from "validator";
import styled from "@emotion/styled";
import AddressInput from "components/AddressInput";
import Button from "components/Button";
import Flex from "components/Flex";
import Text from "components/Text";
import TextInput from "components/TextInput";
import Tile from "components/Tile";
import callApi from "api";
import { useRecoilState } from "recoil";
import { currentUser } from "user/data";
import { errorToast } from "common/toasts";
import { useState } from "react";

const StyledForm = styled.form`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

const BusinessDetailFormRow = styled(Flex)`
  justify-content: space-between;
`;

const InputWrapper = styled(Flex)`
  width: 45%;
`;

const TermsWrap = styled(Flex)`
  margin: 5px 0 20px 0;
`;

const TermsGroup = styled(Flex)`
  padding: 4px;
  border-radius: 4px;
  input {
    margin-right: 10px;
  }
  a {
    color: var(--color-blue);
  }
`;
const validateEIN = (input) => {
  const patternEIN = /^[0-9]\d?-\d{7}$/;
  return !patternEIN.test(input)
    ? "Please enter a valid EIN (eg. 11-1111111)"
    : true;
};

const BusinessDetails = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();
  const [currUser, setCurrUser] = useRecoilState(currentUser);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setLoading(true);
    const response = await callApi(
      `/organizations/${currUser.organization?.id}/customer`,
      "PATCH",
      data
    );
    if (response.ok) {
      const editedOrg = await response.json();
      setCurrUser({
        ...currUser,
        organization: editedOrg,
      });
      navigate("../connect-plaid");
    } else {
      setLoading(false);
      errorToast();
    }
  };
  return (
    <Tile title="Business Details">
      <StyledForm centered onSubmit={handleSubmit(onSubmit)}>
        <BusinessDetailFormRow>
          <InputWrapper>
            <TextInput
              showLabel
              name="legal_name"
              label="Legal Business Name*"
              inputRef={() => register("legal_name", { required: true })}
              placeholder="e.g. My Company, LLC."
              errors={errors}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              showLabel
              name="tax_id"
              label=" Tax Identification Number (TIN)*"
              inputRef={() =>
                register("tax_id", {
                  required: true,
                  validate: validateEIN,
                })
              }
              placeholder="XX-XXXXXXX"
              errors={errors}
            />
          </InputWrapper>
        </BusinessDetailFormRow>
        <BusinessDetailFormRow>
          <InputWrapper>
            <AddressInput
              showLabel
              name="address"
              label="Headquarters Address*"
              placeholder="e.g. 123 Main St, Los Angeles, CA"
              triggerValidation={trigger}
              register={register}
              setValue={setValue}
              errors={errors}
              fullWidth
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              showLabel
              name="website"
              label="Website*"
              inputRef={() =>
                register("website", {
                  required: true,
                  validate: (value) =>
                    !isURL(value) ? "Please enter a valid URL." : true,
                })
              }
              placeholder="e.g. https://trust.co"
              errors={errors}
            />
          </InputWrapper>
        </BusinessDetailFormRow>
        <TermsWrap column>
          <TermsGroup
            centerAlign
            style={{
              border: errors.terms_acceptance
                ? "1px solid var(--color-red)"
                : "none",
            }}
          >
            <input
              type="checkbox"
              {...register("terms_acceptance", { required: true })}
            />
            <Text variant="p2">
              I agree to the&nbsp;
              <Link to="../../terms-and-conditions">
                Terms and Conditions
              </Link>
            </Text>
          </TermsGroup>
          <TermsGroup
            centerAlign
            style={{
              border: errors.officer_confirm
                ? "1px solid var(--color-red)"
                : "none",
            }}
          >
            <input
              type="checkbox"
              {...register("officer_confirm", { required: true })}
            />
            <Text variant="p2">
              I am a controlling officer of my company (CEO, CFO, or other
              executive)
            </Text>
          </TermsGroup>
        </TermsWrap>
        <Flex centered>
          <Button type="submit" loading={loading} size="medium">
            Submit
          </Button>
        </Flex>
      </StyledForm>
    </Tile>
  );
};

export default BusinessDetails;
