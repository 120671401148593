import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePlaidLink } from "react-plaid-link";
// import Flex from "components/Flex";
import Text from "components/Text";
import Button from "components/Button";
import Tile from "components/Tile";
import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue } from "recoil";
import plaidLogo from "assets/logo-plaid.svg";
import {
  ONBOARDING,
  PLAID_CONTEXT,
  PLAID_LINK_MODE,
  PLAID_LINK_TOKEN,
} from "common/constants";
import { currentUser } from "user/data";
import callApi from "api";
// import AccountCard from "components/AccountCard";
import { bankAccountsState, refreshBankAccounts } from "bankAccounts/data";
import { onboardingPaymentRequestState } from "paymentRequests/data";
import { BANK_ACCOUNT_ROUTES } from "bankAccounts/api";
import SplashScreen from "components/SplashScreen";
import { errorToast } from "common/toasts";

// const AccountsHolder = styled(Flex)`
//   width: 100%;
//   & > div {
//     width: 100%;
//     border: 1px solid var(--color-black-one-quarter-opacity);
//     border-radius: 7px;
//   }
// `;

// const SecurityText = styled(Text)`
//   font-style: italic;
// `;

const PlaidButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: var(--color-medium-dark-gray);
  }
`;

const PlaidLogo = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 7px;
`;

const ConnectPlaid = () => {
  // const [step, setStep] = useState("connect");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const { organization } = useRecoilValue(currentUser);
  const [bankAccounts, setBankAccounts] = useRecoilState(bankAccountsState);
  const paymentRequest = useRecoilValue(onboardingPaymentRequestState);
  const onSuccess = useCallback(
    async (publicToken, meta) => {
      if (meta.account.subtype !== "checking" && meta.account.subtype !== "savings") {
        errorToast(
          "Please select a checking or savings account for your initial account. You may add other accounts later in the Settings view."
        )
        return
      }
      setLoading(true);
      const payload = {
        public_token: publicToken,
        meta,
        organization_id: organization?.id,
        payment_request_id: paymentRequest?.id,
      };
      const response = await callApi(BANK_ACCOUNT_ROUTES.ROOT, "POST", payload);
      if (response.ok) {
        refreshBankAccounts(setBankAccounts);
        navigate("../underwriting-check");
      } else {
        setLoading(false);
        errorToast();
      }
    },
    [
      organization,
      navigate,
      setBankAccounts,
      paymentRequest,
    ]
  );
  const { open, ready } = usePlaidLink({ token, onSuccess });
  const setStorageItems = useCallback((linkToken, plaidMode) => {
    localStorage.setItem(PLAID_LINK_TOKEN, linkToken);
    localStorage.setItem(PLAID_LINK_MODE, plaidMode);
    localStorage.setItem(PLAID_CONTEXT, ONBOARDING);
  }, []);
  useEffect(() => {
    const fetchToken = async () => {
      const response = await callApi(
        BANK_ACCOUNT_ROUTES.LINK_TOKEN,
        "POST",
        {}
      );
      const data = await response.json();
      setToken(data.link_token);
    };
    fetchToken();
  }, [bankAccounts]);

  useEffect(() => {
    if (bankAccounts.length) {
      navigate("../underwriting-check");
    }
  }, [bankAccounts, navigate]);

  if (loading) {
    return <SplashScreen text="Processing" />;
  }
  return (
    <Tile  title="Connect your bank account">
      <Text variant="p1" m="0 0 50px 0" centered>
        We&apos;ll use this as your primary payment account for Market and to qualify you for early<br />
        pay discounts and extended terms.
      </Text>
      <PlaidButton
        onClick={() => {
          setStorageItems(token);
          open();
        }}
        loading={!ready}
        variant="outline"
        size="large"
      >
        <PlaidLogo src={plaidLogo} />
        Plaid
      </PlaidButton>
      <Text m="50px 0 50px 0" variant="d">
        *We&apos;re serious about your data. We have bank level security and
        use 256 encryption standards.
      </Text>
      {/* {step === "review" && (
        <Flex column centerAlign>
          <AccountsHolder m="20px 0 20px" gap="10" column centerAlign>
            {bankAccounts.map((account) => (
              <AccountCard key={account.id} account={account} />
            ))}
            <PlaidButton
              onClick={() => {
                setStorageItems(token);
                open();
              }}
              loading={!ready}
              variant="outline"
              size="large"
            >
              + Add new account
            </PlaidButton>
          </AccountsHolder>
          <SecurityText centered m="26px 0" variant="d">
            *We&apos;re serious about your data. We have bank level security and
            use 256 encryption standards.
          </SecurityText>
          <Button
            onClick={() => {
              navigate("../payment-options");
            }}
            size="full"
          >
            Next
          </Button>
        </Flex>
      )} */}
    </Tile>
  );
};

export default ConnectPlaid;
