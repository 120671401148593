import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import Flex from './Flex'
import Text from './Text'

const StyledFooter = styled(Flex)`
    width: 100%;
`

const StyledLink = styled(Link)`
    color: var(--color-black);
    font-size: 14px;
    font-weight: 500;
`

const Footer = () => {
    return (
        <StyledFooter p="20px" m="20px 0 0" gap="3" column centered>
            <Text variant="p2">
                Copyright © 2022 Market, a division of Trust Software Inc. All rights reserved.
            </Text>
            <StyledLink to="terms-and-conditions">Terms and Conditions</StyledLink>
        </StyledFooter>
    )
}

export default Footer
