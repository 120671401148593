import styled from '@emotion/styled'
import Flex from './Flex'
import { capitalize } from 'lodash'
import { useMemo } from 'react'

const StyledInput = styled.input`
    all: unset;
    box-sizing: border-box;
    padding: 12px 3px;
    border-radius: 6px;
    width: 100%;
    transition: border-color 0.2s ease, background-color 0.2s ease, color 0.2s ease;
    ${({ hasError }) =>
        hasError &&
        `border-color: var(--color-red);
        background-color: var(--color-light-red);
        color: var(--color-red);
    `}
    :hover {
        background-color: var(--color-snow-white);
        border-color: var(--color-medium-light-gray);
    }
    :focus {
        border-color: var(--color-blue);
        background-color: var(--color-snow-white);
    }
    :disabled {
        border-color: transparent;
        color: var(--color-dark-gray);
        background-color: var(--color-snow-white);
    }
    ::placeholder {
        color: var(--color-medium-light-gray);
        font-size: 16px;
    }
`

const MoneyInput = styled(Flex)`
    width: 100%;
    text-align: left;
    background-color: var(--color-snow-white);
    color: var(--color-black);
    align-items: baseline;
    &::before {
        content: "$";
        padding-left: 10px;
        color: ${({ isEmpty }) => isEmpty ? 'var(--color-medium-light-gray)' : 'var(--color-black)'};
    }
`

const ColorHolder = styled.div`
    position: relative;
    width: 100%;
    ${({ hasLabel }) => hasLabel && 'margin-top: 22px'};
    ${({ errorPotential }) => errorPotential && 'padding-bottom: 24px'};
`

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 10px;
    font-style: italic;
    left: 0;
    bottom: 6px;
    position: absolute;
`

const Label = styled.label`
    position: absolute;
    top: -20px;
    font-size: 14px;
    font-weight: 200;
    color: var(--color-dark-gray);
`

const CurrencyInput = ({
    inputRef,
    label,
    className,
    errors,
    errorMessage,
    watch,
    ...rest
}) => {
    const inputName = useMemo(() => inputRef().name, [inputRef])
    const eMessage =
        errorMessage ||
        (errors && errors[inputName]?.message) ||
        `${capitalize(label ?? inputName)} is a required field.`
    const hasError = errors && errors[inputName]
    const currValue = watch(inputName)
    const inputProps = {
        type: 'number',
        step: '0.01',
        hasError,
    }
    return (
        <ColorHolder
            errorPotential={!!errors}
            hasLabel={!!label}
            className={className}
        >
            <Flex column>
                {label && <Label htmlFor={inputName}>{label}</Label>}
                <MoneyInput isEmpty={!currValue} centerAlign>
                    <StyledInput {...inputProps} {...inputRef()} {...rest} />
                </MoneyInput>
                {errors && errors[inputName] && (
                    <ErrorMessage>{eMessage}</ErrorMessage>
                )}
            </Flex>
        </ColorHolder>
    )
}

export default CurrencyInput
