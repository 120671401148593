import styled from "@emotion/styled";
import callApi from "api";
import { TOKEN_STORAGE_KEY } from "common/constants";
import Card from "components/Card";
import Text from "components/Text";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { currentUser } from "user/data";
import Button from "../components/Button";
import Flex from "../components/Flex";
import TextInput from "../components/TextInput";
import { USER_ROUTES } from "user/api";
import redX from "assets/red-x-icon.svg";
import { Link } from 'react-router-dom';

const LoginForm = styled.form`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 21px;
`;

const StyledCard = styled(Card)`
  padding-top: 40px;
  height: 472px;
  width: 560px;
`;

const Login = () => {
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });
  const setUserState = useSetRecoilState(currentUser);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    const response = await callApi(USER_ROUTES.LOGIN, "POST", data);
    if (response.ok) {
      const { access_token, user } = await response.json();
      localStorage.setItem(TOKEN_STORAGE_KEY, access_token);
      setUserState(user);
    } else {
      setLoading(false);
      setError(
        response.status === 400
          ? "Incorrect email or password"
          : "Something went wrong. Please try again and if errors persist reach out to support@trust.co"
      );
    }
  };
  return (
    <Flex centered>
      <StyledCard shadow column>
        <Text variant="h2" bold>
          Sign in
        </Text>
        <Text secondary variant="p1" m="12px 0 24px 0">
          Use your email and password to log in.
        </Text>
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Email"
            name="email"
            inputRef={() =>
              register("email", {
                required: "Please enter your email.",
              })
            }
            showLabel
          />
          <TextInput
            type="password"
            label="Password"
            name="password"
            inputRef={() =>
              register("password", {
                required: "Please enter a password.",
              })
            }
            showLabel
          />
          <Link to="../request-password-reset">Forgot password?</Link>
          <Flex p="0 0 20px" gap="10" column centered>
            <Button loading={loading} size="full">Login</Button>
            {error && (
              <Flex gap="6">
                <img src={redX} alt="Red X" />
                <Text variant="p2" bold color="var(--color-red)">
                  {error}
                </Text>
              </Flex>
            )}
          </Flex>
        </LoginForm>
      </StyledCard>
    </Flex>
  );
};

export default Login;
