import styled from "@emotion/styled";
import { useReactTable, flexRender, getCoreRowModel } from '@tanstack/react-table';

const Styles = styled.div`
    table {
        width: 100%;
        border-spacing: 0;
        border: 0px transparent;
        margin-bottom: ${({ variant }) =>
            variant === 'financialInsight' || variant === 'insightSummary' ? 0 : 22}px;
        th,
        td {
            margin: 0;
            border-right: none;
            font-weight: 200;
            padding: 10px 40px;
            font-size: 14px;
            text-align: center;
            :first-of-type {
                text-align: left;
            }
            :last-of-type {
                text-align: right;
            }
        }

        th {
            font-size: 14px;
            font-weight: 700;
            height: 40px;
            background-color: var(--color-ice-white);
            position: relative;
            white-space: nowrap;

            :last-of-type {
                ::after {
                    background: transparent;
                }
            }
        }
        .header-wrap {
            display: flex;
            align-items: center;
        }
        .hide-pipe {
            ::after {
                background: transparent;
            }
        }
        tr {
            transition: background-color 0.2s ease;
            :last-of-type {
                td {
                    border-color: transparent;
                }
            }
            ${({ hasclickhandler }) =>
                hasclickhandler &&
                `
            :hover {
                background-color: var(--color-white);
                cursor: pointer;
            }
        `}
        }
    }
`

const Table = ({ columns, data }) => {
    const {
        getHeaderGroups,
        getRowModel,
    } = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
    })
    return (
        <Styles>
            <table>
                <thead>
                    {getHeaderGroups().map((headerGroup) => {
                        return (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id}>
                                        <div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        )
                    })}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map((cell, index) => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Styles>
    )
}

export default Table
