import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { customersListState } from 'util/fixtures';
import { faker } from '@faker-js/faker'
import Flex from 'components/Flex';
import Button from 'components/Button';
import TextInput from 'components/TextInput';

const RequestPaymentForm = styled.form`
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
`;

const RequestPayment = () => {
    const navigate = useNavigate()
    const { customerId } = useParams()
    const customers = useRecoilValue(customersListState)
    const { id, name, email, payment_requests } = customers.find((c) => c.id === customerId)
    const { register, handleSubmit } = useForm({
        defaultValues: {
            name,
            email,
        }
    })
    const setCustomerList = useSetRecoilState(customersListState);

    const editCustomer = ({ paymentAmount, paymentDueDate }) => {
        const foundIndex = customers.findIndex((c) => c.id === customerId)
        let newCustomerList = [...customers]
        
        newCustomerList[foundIndex] = {
            id,
            name,
            email,
            payment_requests: [...payment_requests, {
                id: faker.datatype.uuid(),
                amount_requested: paymentAmount,
                amount_due_date: paymentDueDate,
                status: 'Pending'
            }],
        }
        setCustomerList(newCustomerList);
        navigate(`../../../customers/${id}`);
    };
    return (
        <Flex column centered>
            <h2>Request Payment from {name}</h2>
            <RequestPaymentForm onSubmit={handleSubmit(editCustomer)}>
                <TextInput
                    type="number"
                    label="Payment Amount"
                    inputRef={() => register("paymentAmount", {
                        required: "Please enter payment amount.",
                    })}
                    showLabel
                />
                <TextInput
                    type="date"
                    label="Payment Due Date"
                    inputRef={() => register("paymentDueDate", {
                        required: "Please enter payment due date.",
                    })}
                    showLabel
                />
                <Button type="submit">Submit</Button>
            </RequestPaymentForm>
        </Flex>
    );
};

export default RequestPayment;
