import styled from "@emotion/styled";
import Text from "components/Text";
import Tile from "components/Tile";
import redCircleX from "assets/icons/redCircleX.svg";

const RejectedIcon = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 20px;
`;

const Message = styled(Text)`
  width: 400px;
  line-height: 24px;
  margin: 20px 0;
`;

const ContactButton = styled.a`
  font-size: 16px;
  line-height: 16px;
  min-height: 16px;
  padding: 15px 0;
  width: 190px;
  text-decoration: none;
  background-color: var(--color-black);
  color: white;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.2s ease;
  letter-spacing: 0.5px;
  :hover {
    box-shadow: -1px 18px 20px -13px var(--color-black-one-third-opacity);
  }
`;

const ApplicationDenied = () => {
  return (
    <Tile>
      <RejectedIcon src={redCircleX} alt="Rejected icon" />
      <Text variant="h1">Your application has been denied</Text>
      <Message variant="p1" centered>
        We apologize, your loan application has been denied. If you believe the
        rejected application is a mistake or want to learn about other options,
        please contact us.
      </Message>
      <ContactButton href="mailto:support@trust.co" target="_blank">
        Contact us
      </ContactButton>
    </Tile>
  );
};

export default ApplicationDenied;
