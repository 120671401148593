import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import downChevron from 'assets/icons/right.svg'
import Flex from './Flex'
import Button from './Button'
import Text from './Text'
import useOnClickOutside from 'common/hooks/useOnClickOutside'

const MenuHolder = styled.div`
    position: relative;
    width: ${({ size }) => {
        if (size === 'small') return 150
        if (size === 'medium') return 200
        return 300
    }}px;
`

const ArrowIcon = styled.img`
    width: 15px;
    height: 15px;
    position: absolute;
    right: 10px;
    transform: rotate(90deg);
    transition: transform 0.3s ease;
`

const Popover = styled.div`
    position: absolute;
    background: var(--color-white);
    border: 1px solid var(--color-light-gray);
    border-radius: 7px 7px 7px 7px;
    z-index: 1;
    overflow-y: auto;
    max-height: 300px;
    width: 100%;
`

const OptionsList = styled(Flex)`
    border: none;
    font-size: 16px;
`

const WhiteButton = styled(Button)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-white);
    background-color: var(--color-snow-white);
    border-radius: 8px;
    transition: all 0.2s ease;
    font-size: 12px;
    font-weight: 700;
    opacity: 1;
    :hover {
        box-shadow: none;
    }
    ${({ editable }) =>
        !editable &&
        `
            :hover {
                background-color: var(--color-snow-white);
            }
            cursor: inherit;
            & img {
                display: none;
            }
        `}
`

const WhiteButtonText = styled(Text)`
    flex: 1;
    text-align: left;
    margin-left: 10px;
`

const Option = styled(Text)`
    cursor: pointer;
    padding: 9px 20px;
    transition: all 0.3s ease;
    :hover {
        background-color: var(--color-snow-white);
    }
    background-color: ${({ selected }) => (selected ? 'var(--color-light-gray)' : 'none')};
    color: ${({ selected }) => (selected ? 'var(--color-black)' : 'var(--color-medium-dark-gray)')};
`

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 14px;
    left: 6px;
    position: absolute;
`

const Select = ({
    name,
    placeholder,
    values,
    inputRef,
    handleClick,
    errors,
    size = 'medium',
    defaultValue = null,
    disabled = false,
}) => {
    const coRef = useRef()
    const [visible, setVisible] = useState(false)
    const [currValue, setCurrValue] = useState(defaultValue ?? placeholder ?? '')

    useEffect(() => {
        setCurrValue(defaultValue ?? placeholder ?? '')
        if (defaultValue) {
            handleClick(defaultValue)
        }
    }, [handleClick, defaultValue, placeholder])

    useOnClickOutside(coRef, () => setVisible(false))
    return (
        <MenuHolder ref={coRef} size={size}>
            <Flex
                onClick={() => {
                    !disabled && setVisible(!visible)
                }}
            >
                <WhiteButton variant="negative" editable={!disabled} type="button">
                    <WhiteButtonText variant="p2" bold={currValue}>
                        {currValue || placeholder}
                    </WhiteButtonText>
                    <ArrowIcon src={downChevron} alt="Chevron arrow" />
                </WhiteButton>
            </Flex>
            {visible && (
                <Popover onClick={() => setVisible(false)}>
                    <OptionsList column>
                        {values.map((val) => (
                            <Option
                                key={val}
                                onClick={() => {
                                    handleClick(val)
                                    setCurrValue(val)
                                }}
                                selected={currValue === val}
                                variant="p2"
                                bold={currValue === val}
                            >
                                {val}
                            </Option>
                        ))}
                    </OptionsList>
                </Popover>
            )}
            {inputRef ? (
                <>
                    <input type="hidden" {...inputRef()} />
                    {errors && errors[inputRef().name] && (
                        <ErrorMessage>
                            {errors[inputRef().name]?.type === "required" && 'This column is required.'}
                        </ErrorMessage>
                    )}
                </>
            ) : (
                <input type="hidden" name={name} value={currValue} />
            )}
        </MenuHolder>
    )
}

Select.propTypes = {
    name: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    values: PropTypes.array,
    inputRef: PropTypes.func,
    handleClick: PropTypes.func,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
}

export default Select
