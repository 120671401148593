import styled from '@emotion/styled'
import Flex from 'components/Flex'
import Text from 'components/Text'
import React from 'react'
import { Link } from 'react-router-dom'

const BreadcrumbLink = styled(Link)`
    color: var(--color-dark-gray);
    font-weight: 300;
`

const Breadcrumbs = ({ breadcrumbList }) => (
    <Flex gap="10" centerAlign>
        {breadcrumbList?.map(({ loc, display }, index) => index === breadcrumbList?.length - 1 ? (
            <Text key={display} variant="h3">{display}</Text>
        ) : (
            <React.Fragment key={display}>
                <BreadcrumbLink to={loc}>{display}</BreadcrumbLink>
                <Text variant="h3">/</Text>
            </React.Fragment>
        ))}
    </Flex>
)

export default Breadcrumbs