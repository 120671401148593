import callApi from "api";
import { atom, selector } from "recoil";
import { currentUser } from "user/data";
import { orderBy } from "lodash";

const fetchPaymentRequestsEffect = ({ setSelf, trigger }) => {
  const fetchPaymentRequests = async () => {
    const response = await callApi("/payment-request");
    const { data } = await response.json();
    setSelf(data);
  };
  if (trigger === "get") {
    fetchPaymentRequests();
  }
};

export const paymentRequestsState = atom({
  key: "PaymentRequests",
  default: [],
  effects: [fetchPaymentRequestsEffect],
});

export const paymentRequestState = atom({
  key: "PaymentRequest",
  default: {
    id: null,
  },
});

export const customerIdState = atom({
  key: "CustomerIdState",
  default: null,
});

export const selectedPaymentRequestState = selector({
  key: "SelectedPaymentRequest",
  get: async ({ get }) => {
    const { id } = get(onboardingPaymentRequestState);
    const response = await callApi("/payment-request");
    const { data } = await response.json();
    const selectedPaymentRequest = data.find((pr) => pr.id === id);
    return selectedPaymentRequest;
  },
});

export const onboardingPaymentRequestState = selector({
  key: "OnboardingPaymentRequest",
  get: ({ get }) => {
    const localStoredPRID = localStorage.getItem("OnboardingPRID");
    if (get(paymentRequestState).id) {
      return get(paymentRequestState);
    } else if (localStoredPRID && localStoredPRID !== "null") {
      return { id: localStoredPRID };
    } else if (get(paymentRequestsState).length) {
      const firstRequest = orderBy(get(paymentRequestsState), "created_at", [
        "desc",
      ])[0];
      return firstRequest;
    }
    return { id: null };
  },
});

export const paymentRequestsByCustomerState = selector({
  key: "PaymentRequestsByCustomer",
  get: async ({ get }) => {
    const customerId = get(customerIdState);
    const organizationId = get(currentUser)?.organization?.id;
    if (!(customerId && organizationId)) return [];
    const response = await callApi(`/payment-request/customer/${customerId}`);
    if (response.ok) {
      const { data } = await response.json();
      return data;
    }
  },
});
