import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import userIcon from "assets/icons/user.svg";
import cardIcon from "assets/icons/card.svg";
import Text from "components/Text";
import Flex from "components/Flex";
import PageSection from "components/PageSection";
import Button from "components/Button";
import { currentUser } from "user/data";
import RequestPaymentModal from "paymentRequests/components/RequestPaymentModal";
import PaymentRequest from "paymentRequests/components/PaymentRequest";
import {
  customerIdState,
  paymentRequestsByCustomerState,
} from "paymentRequests/data";
import Breadcrumbs from "routing/Breadcrumbs";
import callApi from "api";
import { downloadBlob } from "util/helpers";
import ReinviteButton from "components/ReinviteButton";

const CustomerDetailTile = ({ title, value }) => (
  <Flex gap="10" p="40px 0 20px" column>
    <Text variant="h3">{title}</Text>
    <Text variant="p1">{value}</Text>
  </Flex>
);

const CustomerDetails = () => {
  const { customerId } = useParams();
  const [toggle, setToggle] = useState(false);
  const [exporting, setExporting] = useState(false);
  const setCustomerId = useSetRecoilState(customerIdState);
  const paymentRequests = useRecoilValue(paymentRequestsByCustomerState);
  const refreshPaymentRequests = useRecoilRefresher_UNSTABLE(
    paymentRequestsByCustomerState
  );

  const [modalOpen, setModalOpen] = useState(false);
  const currUser = useRecoilValue(currentUser);

  const customers = useMemo(
    () => currUser?.organization?.customers ?? [],
    [currUser]
  );

  const { name, legal_name, user, created_at } = useMemo(
    () => customers.find((c) => c.id === customerId) ?? {},
    [customers, customerId]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        loc: "../customers",
        display: "Customers",
      },
      {
        display: `Customer ${customerId}`,
      },
    ],
    [customerId]
  );
  useEffect(() => {
    setCustomerId(customerId);
  }, [setCustomerId, customerId]);

  useEffect(() => {
    refreshPaymentRequests();
  }, [toggle, refreshPaymentRequests]);

  const allowReinvite = useMemo(() => {
    if (paymentRequests.length) {
      return !paymentRequests[0].payment_request_status.customer_onboarded;
    }
  }, [paymentRequests]);

  const handleClick = useCallback(async () => {
    setExporting(true);
    callApi(`/payment-request/customer/${customerId}/export`).then(
      async (res) => {
        const blob = await res.blob();
        downloadBlob(
          blob,
          `${(name ?? legal_name ?? "Customer")
            .split(" ")
            .join("")}PaymentRequests.csv`
        );
        setExporting(false);
      }
    );
  }, [customerId, name, legal_name]);

  return (
    <>
      <Breadcrumbs breadcrumbList={breadcrumbs} />
      <Flex column gap="30" p="20px 0">
        <PageSection icon={userIcon} title="Customer Details">
          <Flex gap="40">
            <CustomerDetailTile
              title="Business Name"
              value={name ?? legal_name}
            />
            <CustomerDetailTile title="Business Email" value={user?.email} />
            <CustomerDetailTile
              title="Date Joined"
              value={dayjs(created_at).format("YYYY-MM-DD")}
            />
            <CustomerDetailTile
              title="Payment Requests"
              value={paymentRequests?.length ?? 0}
            />
          </Flex>
        </PageSection>
        <PageSection
          icon={cardIcon}
          title="Payment Requests"
          controls={
            <Flex gap="10">
              <Button
                size="small"
                variant="outline"
                onClick={handleClick}
                loading={exporting}
              >
                Export as CSV
              </Button>
              {allowReinvite && <ReinviteButton customerId={customerId} />}
              <Button size="small" onClick={() => setModalOpen(true)}>
                Request Payment
              </Button>
            </Flex>
          }
          tableSection
        >
          {paymentRequests?.length ? (
            paymentRequests.map((paymentRequest) => (
              <PaymentRequest
                key={paymentRequest?.id}
                paymentRequest={paymentRequest}
              />
            ))
          ) : (
            <Flex p="10px 10px 30px" centered>
              <Text variant="h3">
                This customers currently has no payment requests.
              </Text>
            </Flex>
          )}
        </PageSection>
      </Flex>
      <RequestPaymentModal
        isOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
        refresh={() => {
          setToggle(!toggle);
        }}
        customerId={customerId}
      />
    </>
  );
};

export default CustomerDetails;
