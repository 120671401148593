import { Global, css } from "@emotion/react";
import colors from "common/colors";

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        html {
          box-sizing: border-box;
          height: 100%;
          font-family: proxima-nova, sans-serif;
          color: ${colors.black};
          background-color: ${colors.white};
          --color-black: ${colors.black};
          --color-black-semi-opaque: ${colors.blackSemiOpaque};
          --color-black-one-third-opacity: ${colors.blackOneThirdOpacity};
          --color-black-one-quarter-opacity: ${colors.blackOneQuarterOpacity};
          --color-black-low-opacity: ${colors.blackLowOpacity};
          --color-dark-gray: ${colors.darkGray};
          --color-medium-dark-gray: ${colors.mediumDarkGray};
          --color-medium-light-gray: ${colors.mediumLightGray};
          --color-light-gray: ${colors.lightGray};
          --color-ice-white: ${colors.iceWhite};
          --color-white: ${colors.white};
          --color-snow-white: ${colors.snowWhite};
          --color-background: ${colors.snowWhite};
          --color-background-light: ${colors.white};
          --color-green: ${colors.green};
          --color-light-green: ${colors.lightGreen};
          --color-pastel-green: ${colors.pastelGreen};
          --color-blue: ${colors.blue};
          --color-light-blue: ${colors.lightBlue};
          --color-pastel-blue: ${colors.pastelBlue};
          --color-red: ${colors.red};
          --color-light-red: ${colors.lightRed};
          --color-purple: ${colors.purple};
        }
        body,
        #root {
          margin: 0;
          height: 100%;
        }
        button {
          font-family: proxima-nova, sans-serif;
        }
        *,
        *:before,
        *:after {
          box-sizing: border-box;
        }
      `}
    />
  );
};

export default GlobalStyles;
