import styled from '@emotion/styled'
import { useMemo } from 'react'
import Flex from 'components/Flex'
import Text from 'components/Text'
import { formatCurrency } from 'util/helpers';
import dayjs from 'dayjs';
import chevronIcon from 'assets/icons/right.svg'
import { currentUser } from 'user/data';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

const PaymentRequestWrapper = styled(Flex)`
    padding: 15px 50px 15px 20px;
    position: relative;
    border-top: 1px solid var(--color-medium-light-gray);
    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
        background-color: var(--color-snow-white);
    }
`

const RightChevron = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 35px;
    right: 10px;
`

const CustomerPaymentRequest = ({ request }) => {
    const navigate = useNavigate()
    const currUser = useRecoilValue(currentUser)
    const {
        id,
        payment_amount = 0,
        created_at,
    } = useMemo(() => request ?? {}, [request])
    const vendorName = useMemo(
        () => currUser.organization.vendor?.[0]?.legal_name ||
            currUser.organization.vendor?.[0]?.name,
        [currUser]
    )
    return (
        <PaymentRequestWrapper onClick={() => navigate(`../new-request/${id}`)} column>
            <Flex justify="space-between" align="center">
                <Flex align="flex-start" gap="6" column>
                    <Text variant="h3" m="8px 0 0 0">{vendorName}</Text>
                    <Text variant="p1" m="0 0 8px 0">
                        Request Created {dayjs(created_at).format("YYYY/MM/DD")}
                    </Text>
                </Flex>
                <Text variant="h2">{formatCurrency(payment_amount)}</Text>
            </Flex>
            <RightChevron src={chevronIcon} alt="Right arrow" />
        </PaymentRequestWrapper>
    )
}

export default CustomerPaymentRequest
