import dayjs from "dayjs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import {
  paymentsAndRequestsState,
  paymentState,
  refreshPaymentsAndRequests,
} from "payments/data";
import { formatCurrency } from "util/helpers";
import PageSection from "components/PageSection";
import Flex from "components/Flex";
import Text from "components/Text";
import styled from "@emotion/styled";
import Payment from "payments/components/Payment";
import { PAYMENT_STATUS } from "common/enums";

const PaymentsInfo = styled(Flex)`
  background-color: var(--color-light-green);
  width: 100%;
`;

const Payments = () => {
  const navigate = useNavigate();
  const payments = useRecoilValue(paymentState);
  const setPaymentsAndRequests = useSetRecoilState(paymentsAndRequestsState);

  const { totalAmount, totalPaid, nextPaymentDate } = useMemo(() => {
    return payments.reduce(
      ({ totalAmount, totalPaid, nextPaymentDate }, payment) => {
        const amountPaid =
          payment.status === PAYMENT_STATUS.INITIATED ||
          payment.status === PAYMENT_STATUS.COMPLETE
            ? payment.payment_amount
            : 0;
        const dueDate = dayjs(payment.payment_due_date).format("YYYY/MM/DD");
        return {
          totalAmount: totalAmount + payment.payment_amount,
          totalPaid: totalPaid + amountPaid,
          nextPaymentDate:
            !nextPaymentDate || nextPaymentDate > dueDate
              ? dueDate
              : nextPaymentDate,
        };
      },
      { totalAmount: 0, totalPaid: 0, nextPaymentDate: null }
    );
  }, [payments]);

  useEffect(() => {
    if (!payments?.length) {
      navigate("../onboarding/");
    }
  }, [payments, navigate]);

  useEffect(() => {
    refreshPaymentsAndRequests(setPaymentsAndRequests);
  }, [setPaymentsAndRequests]);

  return (
    <Flex column gap="30" p="20px 0">
      <PageSection tableSection>
        <PaymentsInfo centerAlign>
          <Flex gap="5" column p="20px" m="0 50px 0 0">
            <Text variant="p2" bold>
              Total you owe
            </Text>
            <Text variant="h1">{formatCurrency(totalAmount - totalPaid)}</Text>
          </Flex>
          <Flex gap="5" column p="20px">
            <Text variant="p2" bold>
              Total payments
            </Text>
            <Text variant="h1">{formatCurrency(totalPaid)}</Text>
          </Flex>
        </PaymentsInfo>
        <Flex p="20px">
          <Text variant="h3">
            Next payment due on {dayjs(nextPaymentDate).format("MM/DD/YYYY")}
          </Text>
        </Flex>
      </PageSection>
      <PageSection title="Current Payments" tableSection>
        <Flex column>
          {payments.map((p) => (
            <Payment key={p.id} payment={p} />
          ))}
        </Flex>
      </PageSection>
    </Flex>
  );
};

export default Payments;
