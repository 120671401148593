import { PAYMENT_STATUS } from "./enums";

export const TOKEN_STORAGE_KEY = "MarketBearerToken";
export const PLAID_LINK_TOKEN = "plaidLinkToken";
export const PLAID_CONTEXT = "plaidContext";
export const PLAID_LINK_MODE = "plaidLinkMode";
export const PLAID_UPDATE_ID = "plaidUpdateId";
export const ONBOARDING = "onboarding";
export const SETTINGS = "settings";
export const GENERIC_ERROR =
  "Something went wrong. Please try again and if the error persists, reach out to support@trust.co.";

export const paymentStatusMap = {
  [PAYMENT_STATUS.PENDING]: "Pending",
  [PAYMENT_STATUS.INITIATED]: "In Progess",
  [PAYMENT_STATUS.COMPLETE]: "Completed",
  [PAYMENT_STATUS.FAILED]: "Failed",
  [PAYMENT_STATUS.DISPUTED]: "Disputed",
  [PAYMENT_STATUS.REFUNDED]: "Refunded",
};

export const CREATE_CUSTOMER_ERROR_MESSAGES = {
  ALREADY_CONNECTED:
    "Customer with this email already exists and is already connected to this vendor.",
  CONNECTED_TO_ANOTHER_VENDOR:
    "Customer with this email already exists and is already connected to another vendor.",
  NOT_A_CUSTOMER:
    "A user exists with this email, but it's organization is not a customer of any vendor.",
};
