import styled from "@emotion/styled";
import { useMemo } from "react";
import Flex from "components/Flex";
import Text from "components/Text";
import { formatCurrency } from "util/helpers";
import { PAYMENT_REQUEST_CUSTOMER_STATUS, PAYMENT_REQUEST_STATUS } from "common/enums";
import dayjs from "dayjs";
import chevronIcon from "assets/icons/right.svg";
import ProgressBar from "components/ProgressBar";
import { useNavigate } from "react-router-dom";

const paymentRequestCustomerStatusMap = {
    [PAYMENT_REQUEST_CUSTOMER_STATUS.PENDING]: "Pending",
    [PAYMENT_REQUEST_CUSTOMER_STATUS.IN_PROGRESS]: "In Progress",
    [PAYMENT_REQUEST_CUSTOMER_STATUS.AWAITING_ACH]: "ACH Initiated",
    [PAYMENT_REQUEST_CUSTOMER_STATUS.COMPLETED]: "Completed",
};

const PaymentRequestWrapper = styled(Flex)`
  padding: 15px 50px;
  position: relative;
  border-top: 1px solid var(--color-medium-light-gray);
  cursor: pointer;
  transition: all 0.3s ease;
  :hover {
    background-color: var(--color-snow-white);
  }
`;

const RightChevron = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
  right: 10px;
`;

const PaymentRequest = ({ paymentRequest }) => {
    const navigate = useNavigate();
    const {
        id,
        payment_amount = 0,
        payment_due_date,
        payment_request_status,
        status,
    } = useMemo(() => paymentRequest ?? {}, [paymentRequest]);
    const paymentRequestStatusEnum = useMemo(() => {
        const { invoice_sent, customer_onboarded, ach_initiated } =
            payment_request_status;
        if (ach_initiated) return 2;
        if (customer_onboarded) return 1;
        if (invoice_sent) return 0;
    }, [payment_request_status]);
    return (
        <PaymentRequestWrapper
            onClick={() => navigate(`payment-request/${id}/details`)}
            column
        >
            <Flex justify="space-between" align="center">
                <Text variant="h2">{formatCurrency(payment_amount)}</Text>
                <Flex align="flex-end" gap="6" column>
                    <Text variant="p1" m="8px 0 0 0">
                        Payment Due {dayjs(payment_due_date).format("YYYY/MM/DD")}
                    </Text>
                    <Text variant="h3" m="0 0 8px 0">
                        {status === PAYMENT_REQUEST_STATUS.DENIED
                            ? "Denied"
                            : paymentRequestCustomerStatusMap[paymentRequestStatusEnum]}
                    </Text>
                </Flex>
            </Flex>
            {status !== PAYMENT_REQUEST_STATUS.DENIED && (
                <ProgressBar currValue={paymentRequestStatusEnum} totalValue={2} />
            )}
            <RightChevron src={chevronIcon} alt="Right arrow" />
        </PaymentRequestWrapper>
    );
};

export default PaymentRequest;
