import callApi from 'api';
import { atom } from 'recoil';
import { BANK_ACCOUNT_ROUTES } from './api';

const fetchBankAccountsEffect = ({ setSelf, trigger }) => {
    const fetchBankAccounts = async () => {
        const response = await callApi(BANK_ACCOUNT_ROUTES.ROOT)
        if (response.ok) {
            const data = await response.json()
            setSelf(data)
        }
    }
    if (trigger === "get") {
        fetchBankAccounts();
    }
};

export const refreshBankAccounts = async (setBankAccounts) => {
    const response = await callApi(BANK_ACCOUNT_ROUTES.ROOT);
    const data = await response.json();
    setBankAccounts(data);
  };  

export const bankAccountsState = atom({
    key: "BankAccounts",
    default: [],
    effects: [fetchBankAccountsEffect],
});
