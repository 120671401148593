import { toast } from 'react-hot-toast'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Text from 'components/Text'
import { GENERIC_ERROR } from './constants'

const MaxWidthFlex = styled(Flex)`
    width: 100%;
    padding: 0px 20px;
`

const PositionedButton = styled(Button)`
    transform: scale(0.7);
    color: var(--color-medium-dark-gray);
    :hover {
        color: var(--color-medium-light-gray);
    }
    position: absolute;
    top: 8px;
    right: 8px;
`

const Toast = ({ headline, message, dismiss }) => (
    <>
        <MaxWidthFlex centerAlign>
            <Flex column>
                <Text variant="h3">{headline}</Text>
                <Text variant="p2">{message}</Text>
            </Flex>
        </MaxWidthFlex>
        <PositionedButton variant="iconOnly" icon="circleX" onClick={dismiss} />
    </>
)

Toast.propTypes = {
    headline: PropTypes.string,
    message: PropTypes.string,
    dismiss: PropTypes.func,
}

export const successToast = (message, headline = 'Success') =>
    toast.success((t) => (
        <Toast headline={headline} message={message} dismiss={() => toast.dismiss(t.id)} />
    ))

export const errorToast = (message = GENERIC_ERROR, headline = 'Error') =>
    toast.error(
        (t) => <Toast headline={headline} message={message} dismiss={() => toast.dismiss(t.id)} />,
        { id: `${message}Error` }
    )