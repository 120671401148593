import { AnimatePresence, motion } from 'framer-motion'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const TotalProgess = styled.div`
    background: var(--color-light-gray);
    height: 5px;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
`

const CurrentProgess = styled(motion.div)`
    background: var(--color-light-blue);
    height: 100%;
    border-radius: 5px;
`

const ProgressBar = ({ currValue, totalValue }) => {
    const calculateWidth = useMemo(
        () => ((currValue / totalValue) * 100).toFixed(2),
        [currValue, totalValue]
    )
    return (
        <TotalProgess>
            <AnimatePresence>
                <CurrentProgess
                    initial={{ width: '0%' }}
                    animate={{ width: `${calculateWidth}%` }}
                    transition={{ delay: 0.5, duration: 0.3, type: 'tween', ease: 'easeOut' }}
                />
            </AnimatePresence>
        </TotalProgess>
    )
}

ProgressBar.propTypes = {
    title: PropTypes.string,
    currValue: PropTypes.number,
    totalValue: PropTypes.number,
    units: PropTypes.string,
}

export default ProgressBar
