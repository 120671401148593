import styled from "@emotion/styled";
import Flex from "components/Flex";
import PageSection from "components/PageSection";
import Text from "components/Text";
import dayjs from "dayjs";
import {
  customerIdState,
  paymentRequestsByCustomerState,
} from "paymentRequests/data";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import purpleCheckIcon from "assets/icons/purple-check.svg";
import Breadcrumbs from "routing/Breadcrumbs";
import { formatCurrency } from "util/helpers";
import { currentUser } from "user/data";
import Button from "components/Button";
import { PAYMENT_REQUEST_STATUS } from "common/enums";
import InvoiceDetailsModal from "paymentRequests/components/InvoiceDetailsModal";
import ReinviteButton from "components/ReinviteButton";

const DetailsWrapper = styled(Flex)`
  width: 100%;
`;

const StatusSectionRowWrapper = styled(Flex)`
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-medium-light-gray);
  }
`;

const StatusSectionText = styled(Text)`
  color: ${({ incomplete }) =>
    incomplete ? "var(--color-medium-light-gray)" : "var(--color-black)"};
`;

const StatusSection = styled(Flex)`
  width: 400px;
  border: 1px solid var(--color-medium-light-gray);
  border-radius: 10px;
`;

const PaymentDetailsSection = styled(Flex)`
  width: 600px;
`;

const PaymentDetailRow = styled(Flex)`
  padding: 20px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-medium-light-gray);
  }
  & > div:last-of-type {
    max-width: 400px;
    text-align: right;
  }
`;

const PurpleCheckIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`;

const StatusSectionRow = ({ name, date, upNext = false }) => (
  <StatusSectionRowWrapper p="20px" justify="space-between" centerAlign>
    <Flex gap="7" column>
      <StatusSectionText variant="h3" incomplete={!(upNext || date)}>
        {name}
      </StatusSectionText>
      <StatusSectionText variant="p1" incomplete={!(upNext || date)}>
        {date ? dayjs(date).format("YYYY/MM/DD") : "N/A"}
      </StatusSectionText>
    </Flex>
    {date && <PurpleCheckIcon src={purpleCheckIcon} alt="Purple check" />}
  </StatusSectionRowWrapper>
);

const PaymentRequestDetails = () => {
  const { paymentRequestId, customerId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const setCustomerId = useSetRecoilState(customerIdState);
  const { organization } = useRecoilValue(currentUser);
  const paymentRequests = useRecoilValue(paymentRequestsByCustomerState);
  console.log(paymentRequests);
  const {
    payment_amount,
    payment_due_date,
    invoice_date,
    memo,
    payment_request_status,
    created_at,
    status,
  } = useMemo(
    () => paymentRequests.find((pr) => pr.id === paymentRequestId) ?? {},
    [paymentRequests, paymentRequestId]
  );
  const { invoice_sent, customer_onboarded, ach_initiated } = useMemo(
    () => payment_request_status ?? {},
    [payment_request_status]
  );
  console.log(payment_request_status);
  const breadcrumbs = useMemo(
    () => [
      {
        loc: "../../../",
        display: "Customers",
      },
      {
        loc: `../customers/${customerId}`,
        display: `Customer ${customerId}`,
      },
      {
        display: "Payment Details",
      },
    ],
    [customerId]
  );

  const customers = useMemo(
    () => organization?.customers ?? [],
    [organization]
  );

  const { name, legal_name } = useMemo(
    () => customers.find((c) => c.id === customerId) ?? {},
    [customers, customerId]
  );
  useEffect(() => {
    setCustomerId(customerId);
  }, [setCustomerId, customerId]);
  return (
    <>
      <Breadcrumbs breadcrumbList={breadcrumbs} />
      <Flex column gap="30" p="20px 0">
        <PageSection title="Payment Details">
          <DetailsWrapper gap="10" justify="space-between" align="flex-start">
            <PaymentDetailsSection column>
              <PaymentDetailRow justify="space-between">
                <Text variant="h3">Payment Amount</Text>
                <Text variant="p1">{formatCurrency(payment_amount)}</Text>
              </PaymentDetailRow>
              <PaymentDetailRow justify="space-between">
                <Text variant="h3">Advance Fee</Text>
                <Text variant="p1">{organization?.advance_payment_fee}%</Text>
              </PaymentDetailRow>
              <PaymentDetailRow justify="space-between">
                <Text variant="h3">Net Payment Amount</Text>
                <Text variant="p1">
                  {/* TODO: Pull this from persisted amount instead of in memory calculation */}
                  {formatCurrency(
                    payment_amount -
                      (organization?.advance_payment_fee / 100) * payment_amount
                  )}
                </Text>
              </PaymentDetailRow>
              <PaymentDetailRow justify="space-between">
                <Text variant="h3">Invoice Date</Text>
                <Text variant="p1">
                  {dayjs(invoice_date).format("YYYY/MM/DD")}
                </Text>
              </PaymentDetailRow>
              <PaymentDetailRow justify="space-between">
                <Text variant="h3">Payment Due Date</Text>
                <Text variant="p1">
                  {dayjs(payment_due_date).format("YYYY/MM/DD")}
                </Text>
              </PaymentDetailRow>
              <PaymentDetailRow justify="space-between">
                <Text variant="h3">Market Request ID</Text>
                <Text variant="p1">{paymentRequestId}</Text>
              </PaymentDetailRow>
              <PaymentDetailRow justify="space-between">
                <Text variant="h3">Memo</Text>
                <Text variant="p1" secondary={!memo}>
                  {memo ?? "N/A"}
                </Text>
              </PaymentDetailRow>
              {!customer_onboarded ? (
                <ReinviteButton view="payment" customerId={customerId} />
              ) : (
                <Button
                  size="full"
                  onClick={() => setModalOpen(true)}
                  disabled={status !== PAYMENT_REQUEST_STATUS.APPROVED}
                >
                  View Invoice
                </Button>
              )}
            </PaymentDetailsSection>
            <StatusSection column>
              <StatusSectionRow name="Invoice Sent" date={invoice_sent} />
              <StatusSectionRow
                name="Customer Onboarded"
                date={customer_onboarded}
                upNext={invoice_sent && !customer_onboarded}
              />
              <StatusSectionRow
                name="ACH Initiated"
                date={ach_initiated}
                upNext={customer_onboarded && !ach_initiated}
              />
            </StatusSection>
          </DetailsWrapper>
        </PageSection>
      </Flex>
      <InvoiceDetailsModal
        invoiceDetails={{
          billedTo: legal_name ?? name,
          billedFrom: organization?.legal_name ?? organization?.name,
          billedOn: dayjs(created_at).format("MMMM D, YYYY"),
          dueDate: dayjs(payment_due_date).format("MMMM D, YYYY"),
          paymentRequestId,
        }}
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default PaymentRequestDetails;
