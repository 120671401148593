import React, { useState } from "react";
import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "./Logo";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { currentUser } from "user/data";
import Flex from "./Flex";
import { TOKEN_STORAGE_KEY } from "common/constants";
import { availableRoutes } from "routing/navItems";
import NavItem from "routing/NavItem";
import logOutIcon from "assets/logout-icon.svg";

const StickyWrapper = styled.div`
  z-index: 12;
  box-sizing: border-box;
  height: 100%;
  position: absolute;
`;

const LogoWrapper = styled(motion.div)`
  overflow: hidden;
  margin-left: 17px;
  min-width: 37px;
`;

const PositionedLogo = styled(Logo)`
  margin-top: 32px;
  margin-left: 7px;
  object-fit: cover;
`;

const SidebarWrap = styled(motion.div)`
  position: sticky;
  width: 80px;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-background-light);
  overflow: hidden;
`;

const NavItems = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const LogOutNavLink = styled(NavItem)`
  margin-bottom: 20px;
`;

const Sidebar = () => {
  const [imageWidth, setImageWidth] = useState(34);
  const [hovering, setHovering] = useState(false);

  const resetUser = useResetRecoilState(currentUser);
  const navItems = useRecoilValue(availableRoutes);
  return (
    <StickyWrapper>
      <AnimatePresence>
        <SidebarWrap
          whileHover={{ width: "250px" }}
          onHoverStart={() => {
            setHovering(true);
            setImageWidth(160);
          }}
          onHoverEnd={() => {
            setHovering(false);
            setImageWidth(34);
          }}
        >
          <Flex column>
            <LogoWrapper
              initial={{ width: 34 }}
              animate={{ width: imageWidth }}
            >
              <PositionedLogo width="137px" height="30px" />
            </LogoWrapper>
            <React.Suspense fallback={<div></div>}>
              <NavItems>
                {navItems.map(
                  ({ path, label, icon, showInNav }) =>
                    showInNav && (
                      <NavItem
                        key={`${path}Link`}
                        path={path}
                        label={label}
                        icon={icon}
                        expanded={hovering}
                      />
                    )
                )}
              </NavItems>
            </React.Suspense>
          </Flex>
          <LogOutNavLink
            path="/login"
            expanded={hovering}
            label="Logout"
            icon={logOutIcon}
            onClick={() => {
              localStorage.clear(TOKEN_STORAGE_KEY);
              resetUser();
            }}
          >
            Logout
          </LogOutNavLink>
        </SidebarWrap>
      </AnimatePresence>
    </StickyWrapper>
  );
};

export default Sidebar;
