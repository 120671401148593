import styled from "@emotion/styled";
import callApi from "api";
import { errorToast } from "common/toasts";
import Button from "components/Button";
import PasswordInput from "components/PasswordInput";
import TextInput from "components/TextInput";
import Tile from "components/Tile";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { currentUser } from "user/data";
import { validateStrongPW } from "util/helpers";

const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 22px;
`;

const CustomerSignUp = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const password = useWatch({
    control,
    name: "password",
  });
  const setCurrUser = useSetRecoilState(currentUser);
  const [loading, setLoading] = useState(false);
  const onSubmit = async ({ first_name, last_name, password }) => {
    setLoading(true);
    const response = await callApi("/users/me", "PATCH", {
      first_name,
      last_name,
      password,
    });
    if (response.ok) {
      const newUser = await response.json();
      setCurrUser(newUser);
      navigate("../business-details");
    } else {
      setLoading(false);
      errorToast(
        "An error occurred when updating your information. Please try again."
      );
    }
  };

  return (
    <Tile
      forcedWidth="560"
      title="Welcome!"
      subtitle="You've been invited to pay a Vendor using Market. You may also qualify for extended
        payment terms. Create your account to get started."
    >
      <SignUpForm onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="First Name"
          name="first_name"
          inputRef={() =>
            register("first_name", {
              required: "Please enter your first name.",
            })
          }
          errors={errors}
          showLabel
        />
        <TextInput
          label="Last Name"
          name="last_name"
          inputRef={() =>
            register("last_name", {
              required: "Please enter your last name.",
            })
          }
          errors={errors}
          showLabel
        />
        <PasswordInput
          label="Password"
          name="password"
          inputRef={() =>
            register("password", {
              required: true,
              validate: (value) => validateStrongPW(value),
            })
          }
          errors={errors}
          toggleVisibility
          showLabel
        />
        <PasswordInput
          label="Confirm Password"
          name="confirm_password"
          inputRef={() =>
            register("confirm_password", {
              validate: (value) =>
                value === password || "Passwords do not match",
            })
          }
          errors={errors}
          toggleVisibility
          showLabel
        />
        <Button type="submit" loading={loading} size="full">
          Next
        </Button>
      </SignUpForm>
    </Tile>
  );
};

export default CustomerSignUp;
