import { atom } from 'recoil';
import { faker } from '@faker-js/faker'

export const customersListState = atom({
    key: 'CustomersList',
    default: [
        {
            id: "243b298e-fa11-4c7c-98e8-e8dd70713e3c",
            name: "Ernser, Ullrich and Dibbert",
            email: "Alessandra19@hotmail.com",
            payment_requests: []
        },
        {
            id: "871317ca-3f48-4284-a09e-f34eca9ca8a3",
            name: "Quitzon - Stracke",
            email: "Bell_Boehm@gmail.com",
            payment_requests: [
                {
                    id: "59e65ffc-117c-4ed0-8b0d-43f98259b0c0",
                    amount_requested: 161232,
                    amount_due_date: "2023-01-21T12:49:06.506Z",
                    status: "In Progress"
                },
                {
                    id: "f2856d13-0eef-475c-9c36-55152dd15f7f",
                    amount_requested: 375866,
                    amount_due_date: "2023-07-11T04:07:16.011Z",
                    status: "Complete"
                },
                {
                    id: "927550e4-0805-4bee-96ea-0608f67d5133",
                    amount_requested: 126579,
                    amount_due_date: "2022-11-19T14:48:25.730Z",
                    status: "Awaiting ACH"
                },
                {
                    id: "4509a524-5ec1-4840-b3b3-c548cbc3ec49",
                    amount_requested: 923458,
                    amount_due_date: "2023-04-29T10:57:33.342Z",
                    status: "In Progress"
                }
            ]
        },
        {
            id: "b116edf0-3c30-4f63-84af-48ebcc8c8a6e",
            name: "Jacobs LLC",
            email: "Jamison.Zulauf@hotmail.com",
            payment_requests: [
                {
                    id: "f5a6d61f-ca17-4680-ae58-0bcc4dfea6dd",
                    amount_requested: 125884,
                    amount_due_date: "2023-06-17T20:40:27.633Z",
                    status: "Complete"
                },
                {
                    id: "4a190627-aa47-44c2-9492-ded5a68cbeca",
                    amount_requested: 965508,
                    amount_due_date: "2022-08-29T23:04:38.957Z",
                    status: "Awaiting ACH"
                }
            ]
        },
        {
            id: "582cb4a2-34dc-4792-928e-42126def414c",
            name: "Jenkins LLC",
            email: "Vena.Goldner@gmail.com",
            payment_requests: [
                {
                    id: "5a8d02eb-1125-4872-8473-7fd38039ccd5",
                    amount_requested: 210618,
                    amount_due_date: "2022-10-27T17:30:10.283Z",
                    status: "Complete"
                }
            ]
        },
        {
            id: "1b0767ba-9729-4d5d-83e0-3abae64b8255",
            name: "Hartmann, O'Keefe and Herzog",
            email: "Roscoe_Runolfsson1@yahoo.com",
            payment_requests: [
                {
                    id: "3d6242c1-b95f-46f1-a349-f6be6499cbe4",
                    amount_requested: 544825,
                    amount_due_date: "2023-08-06T05:46:24.073Z",
                    status: "Complete"
                },
                {
                    id: "dda95c67-5d21-45db-917c-88c1d7e35993",
                    amount_requested: 586391,
                    amount_due_date: "2023-04-01T12:03:39.336Z",
                    status: "Complete"
                }
            ]
        },
        {
            id: "31812e94-95f8-461a-8596-930ef660f6ed",
            name: "Zulauf - Wolf",
            email: "Janelle32@gmail.com",
            payment_requests: []
        },
        {
            id: "fd6fdf19-51bb-44a7-8f12-a03773d53b2e",
            name: "Christiansen - Green",
            email: "Alaina99@gmail.com",
            payment_requests: [
                {
                    id: "c69b1f1b-ba17-48cd-82db-ecb6235c83f2",
                    amount_requested: 340822,
                    amount_due_date: "2023-02-16T19:21:43.751Z",
                    status: "Pending"
                },
                {
                    id: "fa82986a-9f45-4366-b930-2bb7e3dd5282",
                    amount_requested: 132426,
                    amount_due_date: "2022-11-13T00:00:27.531Z",
                    status: "In Progress"
                },
                {
                    id: "47369f8a-a9f7-4e0a-a29f-799124136edb",
                    amount_requested: 460078,
                    amount_due_date: "2023-07-07T18:29:32.856Z",
                    status: "Awaiting ACH"
                },
                {
                    id: "6d1c184b-e1c4-4625-8d0e-3d456e54b245",
                    amount_requested: 478448,
                    amount_due_date: "2023-06-27T18:50:23.796Z",
                    status: "Pending"
                }
            ]
        },
        {
            id: "e0f64d2f-a751-4ebd-9195-f9f75b01c024",
            name: "Marks - Lueilwitz",
            email: "Elwin31@gmail.com",
            payment_requests: [
                {
                    id: "c890a930-3de4-4b85-9def-3cf86a6f2e08",
                    amount_requested: 293741,
                    amount_due_date: "2022-11-17T23:49:38.985Z",
                    status: "In Progress"
                },
                {
                    id: "43de4402-85fd-410c-832d-5be9e353ee7e",
                    amount_requested: 801546,
                    amount_due_date: "2023-01-31T18:02:36.817Z",
                    status: "In Progress"
                },
                {
                    id: "bdada394-2d51-4ab2-99a3-84ae5b4aedf5",
                    amount_requested: 601781,
                    amount_due_date: "2023-06-09T13:19:59.398Z",
                    status: "Awaiting ACH"
                },
                {
                    id: "546d3e80-3000-4a0e-b50f-7da2ef7c8f8d",
                    amount_requested: 333504,
                    amount_due_date: "2023-05-24T13:53:49.061Z",
                    status: "Complete"
                },
                {
                    id: "82c643cc-0e24-44ab-8f89-dff3c34ee132",
                    amount_requested: 832065,
                    amount_due_date: "2022-12-09T09:06:11.036Z",
                    status: "Awaiting ACH"
                },
                {
                    id: "394537c6-9805-425d-bec2-009f4350621f",
                    amount_requested: 74481,
                    amount_due_date: "2023-04-04T13:37:02.258Z",
                    status: "Pending"
                },
                {
                    id: "f6141922-2109-4a1a-b761-f85bcaa49d0d",
                    amount_requested: 945315,
                    amount_due_date: "2023-07-11T05:28:00.634Z",
                    status: "Complete"
                },
                {
                    id: "a6c16c3d-c436-41f0-b5fb-846c92f72476",
                    amount_requested: 928432,
                    amount_due_date: "2023-06-07T03:39:41.694Z",
                    status: "Awaiting ACH"
                }
            ]
        },
        {
            id: "e91b7166-fb67-49d7-86b0-dc3d4745775c",
            name: "Hegmann, Frami and Sporer",
            email: "Elsie24@gmail.com",
            payment_requests: [
                {
                    id: "386948c6-df38-4bf9-9a50-cbc73a34e289",
                    amount_requested: 350186,
                    amount_due_date: "2023-01-11T01:32:23.411Z",
                    status: "Pending"
                },
                {
                    id: "ea93398a-b1f4-4482-807f-f8565cad3f80",
                    amount_requested: 605321,
                    amount_due_date: "2022-09-02T08:22:29.710Z",
                    status: "Awaiting ACH"
                },
                {
                    id: "003d5d36-c3f2-4064-98de-b9bc6e189ec7",
                    amount_requested: 197076,
                    amount_due_date: "2023-05-25T15:45:36.191Z",
                    status: "Awaiting ACH"
                },
                {
                    id: "e85fecba-adf5-4123-84d7-15010bfcc7fa",
                    amount_requested: 291886,
                    amount_due_date: "2022-11-03T11:55:45.370Z",
                    status: "Pending"
                },
                {
                    id: "9568f99a-650e-4067-927d-b29dec5cd76e",
                    amount_requested: 879174,
                    amount_due_date: "2022-12-12T11:47:42.169Z",
                    status: "Awaiting ACH"
                },
                {
                    id: "bd47cfc5-a908-45f1-8f10-2bdace92ef22",
                    amount_requested: 538346,
                    amount_due_date: "2022-10-12T03:51:09.231Z",
                    status: "Complete"
                },
                {
                    id: "8fafd1bf-a89c-4d1f-b5ea-c59e1158fef0",
                    amount_requested: 953472,
                    amount_due_date: "2022-10-20T06:54:27.893Z",
                    status: "Awaiting ACH"
                },
                {
                    id: "f5f61967-0272-48a1-9ff3-965985704adc",
                    amount_requested: 273818,
                    amount_due_date: "2022-12-11T17:52:29.839Z",
                    status: "Complete"
                },
                {
                    id: "0717495a-54ce-40a4-90b9-c8d73a15f764",
                    amount_requested: 606822,
                    amount_due_date: "2022-09-13T09:37:18.719Z",
                    status: "Awaiting ACH"
                }
            ]
        },
        {
            id: "ec5c400d-8d77-41ed-9c81-e60f0885cdaa",
            name: "Smith LLC",
            email: "Ahmed.Schmeler@hotmail.com",
            payment_requests: [
                {
                    id: "cb3087c5-d604-4480-9e5a-88b4bbaf5975",
                    amount_requested: 167409,
                    amount_due_date: "2022-09-12T18:29:49.636Z",
                    status: "In Progress"
                },
                {
                    id: "75b43488-de89-4fa7-9dba-1a3942e3add4",
                    amount_requested: 934366,
                    amount_due_date: "2023-07-15T00:56:44.813Z",
                    status: "Complete"
                }
            ]
        }
    ],
})

const getLoans = () => {
    const createdLoans = []
    for (let i = 0; i < 10; i++) {
        createdLoans.push({
            id: faker.datatype.uuid(),
            vendor: faker.company.name(),
            amount_left: Math.floor(Math.random() * 400) + 100,
            total_amount: Math.floor(Math.random() * 4500) + 500,
            next_bill_date: faker.date.future(),
        })
    }
    return createdLoans
}

export const integrationsListState = atom({
    key: 'IntegrationsList',
    default: [],
})

export const loansListState = atom({
    key: 'LoansList',
    default: getLoans(),
})

export const customerState = atom({
    key: 'Customer',
    default: {
        completedFlow: false,
    },
})
