import styled from '@emotion/styled';
import callApi from 'api';
import { errorToast } from 'common/toasts';
import Button from 'components/Button'
import Flex from 'components/Flex';
import Text from 'components/Text';
import Tile from 'components/Tile'
import dayjs from 'dayjs';
import { selectedPaymentRequestState } from 'paymentRequests/data';
import PaymentTermsAgreement from 'payments/components/PaymentTermsAgreement';
import { selectedPaymentOptionState } from 'payments/data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentUser, refreshUser } from 'user/data';
import { formatCurrency } from 'util/helpers';

const TextboxWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid var(--color-light-gray);
    overflow: hidden;
`;

const Textbox = styled(Flex)`
    width: 100%;
    height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px;
    border-radius: 10px;    
`;

const TermsAcceptanceWrapper = styled(Flex)`
    width: 100%;
    & > div {
        width: 48%;
    }
`;

const BoldText = styled.span`
    font-weight: 700;
`;

const PaymentDetailRow = styled(Flex)`
    padding: 20px 0;
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-medium-light-gray);
    }
    & > div:last-of-type {
        max-width: 400px;
        text-align: right;
    }
`;

const TermSummaryRow = styled(Flex)`
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-light-gray);
`;

const TermsAcceptance = () => {
    const navigate = useNavigate();
    const { paymentRequestId } = useParams();
    const [loading, setLoading] = useState(false);
    const [currUser, setCurrUser] = useRecoilState(currentUser);
    const selectedLoanOption = useRecoilValue(selectedPaymentOptionState);
    const selectedPaymentRequest = useRecoilValue(selectedPaymentRequestState);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { payment_amount, payment_due_date, invoice_date, memo } = useMemo(
        () => selectedPaymentRequest ?? {},
        [selectedPaymentRequest]
    );
    const vendorName = useMemo(
        () => currUser.organization.vendor?.[0]?.legal_name ||
            currUser.organization.vendor?.[0]?.name,
        [currUser]
    );
    const onSubmit = useCallback(async () => {
        const { vendor_id, payment_due_date, payment_amount } = selectedPaymentRequest;
        setLoading(true);
        const response = await callApi(`/loan/${currUser.organization.id}`, 'POST', {
            loan_option_id: selectedLoanOption.id,
            vendor_id,
            payment_due_date: dayjs(payment_due_date).add(selectedLoanOption.number_of_days, 'd'),
            payment_amount: payment_amount + (selectedLoanOption.fee_percentage * payment_amount) / 100,
            payment_request_id: paymentRequestId,
        });
        if (response.ok) {
            navigate('../../../payments');
        } else {
            errorToast('An error occurred when accepting the payment. Please try again.');
            setLoading(false);
        }
    }, [currUser, selectedPaymentRequest, selectedLoanOption, navigate, paymentRequestId]);

    useEffect(() => {
        if (errors.payment_confirm) {
            errorToast('Terms must be accepted to initialize payment.');
        }
    }, [errors.payment_confirm]);

    useEffect(() => {
        refreshUser(setCurrUser)
    }, [setCurrUser]);
    return (
        <Tile title="Review and Accept Payment Terms">
            <TermsAcceptanceWrapper justify="space-between">
                <Flex column>
                    <Text variant="h2">Payment Details</Text>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Invoiced Amount</Text>
                        <Text variant="p1">
                            {formatCurrency(payment_amount)}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Fee to Extend with Market</Text>
                        <Text variant="p1">
                            {formatCurrency((selectedLoanOption.fee_percentage * payment_amount) / 100)}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Amount Owed</Text>
                        <Text variant="p1">
                            {formatCurrency(
                                payment_amount + (selectedLoanOption.fee_percentage * payment_amount) / 100
                            )}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Date Started</Text>
                        <Text variant="p1">{dayjs(invoice_date).format("YYYY/MM/DD")}</Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Date Due</Text>
                        <Text variant="p1">
                            {dayjs(payment_due_date)
                                .add(selectedLoanOption.number_of_days, 'd')
                                .format("YYYY/MM/DD")}
                        </Text>
                    </PaymentDetailRow>
                    <PaymentDetailRow justify="space-between">
                        <Text variant="h3">Memo</Text>
                        <Text variant="p1" secondary={!memo}>{memo ?? 'N/A'}</Text>
                    </PaymentDetailRow>
                </Flex>
                <Flex gap="10" column>
                    <Text variant="h2">Extended Payment Terms Agreement</Text>
                    <TextboxWrapper>
                        <Textbox column>
                            <Text variant="h2" centered>SUMMARY OF TERMS</Text>
                            <TermSummaryRow>
                                <Text variant="p2">Effective Date<br />(“<BoldText>Effective Date</BoldText>”):</Text>
                                <Text variant="p2">{dayjs().format("YYYY/MM/DD")}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Customer Legal Name<br />(“<BoldText>Customer</BoldText>”):</Text>
                                <Text variant="p2">{currUser.organization.legal_name}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Customer Jurisdiction of Formation/Incorporation:</Text>
                                <Text variant="p2">{currUser.organization.jurisdiction}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Customer Entity Type:</Text>
                                <Text variant="p2">{currUser.organization.entity_type}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Vendor Legal Name<br />(“<BoldText>Vendor</BoldText>”):</Text>
                                <Text variant="p2">{vendorName}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Invoiced Amount<br />(“<BoldText>Invoiced Amount</BoldText>”):</Text>
                                <Text variant="p2">{formatCurrency(payment_amount)}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Invoice Date<br />(“<BoldText>Invoice Date</BoldText>”):</Text>
                                <Text variant="p2">{dayjs(invoice_date).format("YYYY/MM/DD")}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Due Date of Payment to Vendor<br />(“<BoldText>Vendor Payment Date</BoldText>”):</Text>
                                <Text variant="p2">{dayjs(payment_due_date).format("YYYY/MM/DD")}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Length of the Payment Extension<br />(“<BoldText>Payment Extension Period</BoldText>”):</Text>
                                <Text variant="p2">{selectedLoanOption.number_of_days} days</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Fee<br />(“<BoldText>Extension Fee</BoldText>”):</Text>
                                <Text variant="p2">{formatCurrency((selectedLoanOption.fee_percentage * payment_amount) / 100)}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Extended Payment Due Date<br />(“<BoldText>Extended Payment Due Date</BoldText>”):</Text>
                                <Text variant="p2">{dayjs(payment_due_date).add(selectedLoanOption.number_of_days, 'd').format("YYYY/MM/DD")}</Text>
                            </TermSummaryRow>
                            <TermSummaryRow>
                                <Text variant="p2">Extended Payment Amount<br />(“<BoldText>Extended Payment Amount</BoldText>”):</Text>
                                <Text variant="p2">
                                    {formatCurrency(
                                        payment_amount + (selectedLoanOption.fee_percentage * payment_amount) / 100
                                    )}
                                </Text>
                            </TermSummaryRow>
                            <PaymentTermsAgreement />
                        </Textbox>
                    </TextboxWrapper>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex m="0 0 10px 0" gap="5">
                            <Text bold>I agree to the Extended Payment Terms Agreement</Text>
                            <input
                                type="checkbox"
                                {...register("payment_confirm", { required: true })}
                            />
                        </Flex>
                        <Button size="full" type="submit" loading={loading}>
                            Confirm
                        </Button>
                    </form>
                </Flex>
            </TermsAcceptanceWrapper>
        </Tile>
    );
};

export default TermsAcceptance;
