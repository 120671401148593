import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentUser, organizationUnderwritingCheckState } from "user/data";
import { selectedPaymentRequestState } from "paymentRequests/data";
import callApi from "api";
import { errorToast } from "common/toasts";
import SplashScreen from "components/SplashScreen";

const UnderwritingCheck = () => {
  const navigate = useNavigate();
  const currUser = useRecoilValue(currentUser);
  const pr = useRecoilValue(selectedPaymentRequestState);
  const setCheckState = useSetRecoilState(organizationUnderwritingCheckState);

  useEffect(() => {
    let polls = 0;
    if (currUser?.organization?.id && pr?.id) {
      const checkUnderwriting = setInterval(async () => {
        const response = await callApi(
          `/organizations/${currUser.organization.id}/underwriting-check?prid=${pr.id}`
        );
        if (response.ok) {
          const { underwriting_check } = await response.json();
          switch (underwriting_check) {
            case true:
              clearInterval(checkUnderwriting);
              setCheckState(true);
              navigate("../payment-options");
              break;
            case false:
              clearInterval(checkUnderwriting);
              setCheckState(false);
              navigate("../application-denied");
              break;
            default:
              break;
          }
        }
        polls += 1;
        if (polls === 100) {
          clearInterval(checkUnderwriting);
          errorToast(
            "There was an error when checking for underwriting status. Please try again later."
          );
        }
      }, 1000);
    }
  }, [currUser, navigate, pr, setCheckState]);

  return <SplashScreen text="Processing" />;
};

export default UnderwritingCheck;
