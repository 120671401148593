import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Flex from './Flex'
import { capitalize } from 'lodash'

const StyledInput = styled.input`
    all: unset;
    box-sizing: border-box;
    padding: ${({ hasIcon }) => (hasIcon ? '9px 8px' : '12px 10px')};
    border-radius: 6px;
    ${({ hasIcon }) => hasIcon && 'padding-left: 24px'};
    transition: border-color 0.2s ease, background-color 0.2s ease, color 0.2s ease;
    width: 100%;
    text-align: left;
    background-color: var(--color-snow-white);
    color: var(--color-black);
    ${({ hasError }) =>
        hasError &&
        `border-color: var(--color-red);
        background-color: var(--color-light-red);
        color: var(--color-red);
    `}
    ${({ isSuccess }) =>
        isSuccess &&
        `border-color: var(--color-green);
        background-color: var(--color-light-green);
        color: var(--color-green);
        padding-right: 20px;
    `}
    :hover {
        background-color: var(--color-snow-white);
        border-color: var(--color-medium-light-gray);
    }
    :focus {
        border-color: var(--color-blue);
        background-color: var(--color-snow-white);
    }
    :disabled {
        border-color: transparent;
        color: var(--color-dark-gray);
        background-color: var(--color-snow-white);
    }
    ::placeholder {
        color: var(--color-medium-light-gray);
        font-size: 16px;
    }
`

const ColorHolder = styled.div`
    position: relative;
    width: 100%;
    ${({ hasLabel }) => hasLabel && 'margin-top: 22px'};
    ${({ errorPotential }) => errorPotential && 'padding-bottom: 24px'};
`

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 10px;
    font-style: italic;
    left: 0;
    bottom: 6px;
    position: absolute;
`

const Label = styled.label`
    position: absolute;
    top: -20px;
    font-size: 14px;
    font-weight: 200;
    color: var(--color-dark-gray);
`

const TextInput = ({
    inputRef,
    name,
    label,
    className,
    errors,
    errorMessage,
    errorStyles,
    isSuccess,
    showLabel = false,
    ...rest
}) => {
    const eMessage =
        errorMessage ||
        (errors && errors[name]?.message) ||
        `${capitalize(label ?? name)} is a required field.`
    const hasError = errors && errors[name]
    const inputProps = {
        type: 'text',
        name,
        hasError,
        isSuccess,
    }

    return (
        <ColorHolder
            errorPotential={!!errors}
            hasLabel={!!label && showLabel}
            className={className}
        >
            <Flex column>
                {label && showLabel && <Label htmlFor={name}>{label}</Label>}
                <div>
                    {inputRef && <StyledInput {...inputProps} {...inputRef()} {...rest} />}
                    {!inputRef && <StyledInput {...inputProps} {...rest} />}
                    {errors && errors[name] && (
                        <ErrorMessage style={errorStyles}>
                            {eMessage}
                        </ErrorMessage>
                    )}
                </div>
            </Flex>
        </ColorHolder>
    )
}

TextInput.propTypes = {
    inputRef: PropTypes.func,
    className: PropTypes.string,
    errors: PropTypes.object,
    name: PropTypes.string,
    variant: PropTypes.string,
    icon: PropTypes.string,
    errorMessage: PropTypes.string,
    errorStyles: PropTypes.object,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    isSuccess: PropTypes.bool,
}

export default TextInput
