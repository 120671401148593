import styled from "@emotion/styled";

const Content = styled.div`
  padding: 0px 4px;
  min-height: ${({ activeLayout }) => activeLayout ? 675 : 640}px;
`;

const Body = ({ children, activeLayout = false }) => {
  return <Content activeLayout={activeLayout}>{children}</Content>;
};

export default Body;
