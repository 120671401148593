import styled from "@emotion/styled";
import Logo from "./Logo";
import Loader from "./Loader";
import Text from "./Text";
import Card from "./Card";
import Flex from "./Flex";

const MaxFlex = styled(Flex)`
  height: 100%;
  width: 100%;
`;

const FixedSizeCard = styled(Card)`
  height: 550px;
  width: 550px;
`;

const SplashScreen = ({ text }) => (
  <MaxFlex centered>
    <FixedSizeCard gap="20" shadow centered>
      <Logo />
      <Loader />
      {text && <Text variant="p1" bold>{text}</Text>}
    </FixedSizeCard>
  </MaxFlex>
);

export default SplashScreen;
