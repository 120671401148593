import callApi from 'api';
import { TOKEN_STORAGE_KEY } from 'common/constants';
import { errorToast, successToast } from 'common/toasts';
import Button from 'components/Button';
import Loader from 'components/Loader';
import PasswordInput from 'components/PasswordInput';
import Tile from 'components/Tile';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { currentUser } from 'user/data';

const PasswordReset = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const resetUser = useResetRecoilState(currentUser);
    const onSubmit = useCallback(async ({ password }) => {
        const response = await callApi('/users/me', 'PATCH', { id: userId, password });
        if (response.ok) {
            successToast('Password successfully changed. Please log in again.');
            navigate("../login");
        } else {
            errorToast('Something went wrong. Please try again.');
        }
    }, [userId, navigate]);
    useEffect(() => {
        const getToken = async () => {
            localStorage.clear();
            resetUser();
            const response = await callApi(`/users/verify-token?token=${token}`);
            if (response.ok) {
                const { access_token, user } = await response.json();
                localStorage.setItem(TOKEN_STORAGE_KEY, access_token);
                setUserId(user.id);
                setLoading(false);
            } else {
                errorToast("There was an error when verifying user.");
                navigate("../login");
            }
        };
        if (token) {
            getToken();
        }
    }, [setUserId, token, resetUser, navigate]);
    if (loading) return <Loader />;
    return (
        <Tile title="Select a new password">
            <form onSubmit={handleSubmit(onSubmit)}>
                <PasswordInput
                    label="New Password"
                    inputRef={() =>
                        register('password', {
                            required: true,
                        })
                    }
                    errors={errors}
                    showLabel
                />
                <PasswordInput
                    label="Confirm Password"
                    inputRef={() =>
                        register('confirm_password', {
                            required: true,
                        })
                    }
                    errors={errors}
                    showLabel
                />
                <Button
                    size="full"
                    onClick={handleSubmit(onSubmit)}
                    loading={loading}
                >
                    Send Link
                </Button>
            </form>
        </Tile>
    );
};

export default PasswordReset;