import styled from '@emotion/styled';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Text from 'components/Text';
import bankIcon from 'assets/icons/bank-illo.svg';
import greenCheckIcon from 'assets/icons/green-check.svg'

const BankAccountWrapper = styled(Flex)`
    border: 1px solid var(--color-medium-light-gray);
    border-radius: 7px;
    height: 64px;

    & > div {
        width: 30%;
    }
`

const BankName = styled(Flex)`
    width: 158px;
`

const BankIcon = styled.img`
    width: 20px;
    height: 20px;
`

const SetAsPrimaryButton = styled(Button)`
    background-color: var(--color-green);
`

const PrimaryAccount = styled(Flex)`
    width: 158px;
`

const GreenText = styled(Text)`
    color: var(--color-green);
    font-size: 14px;
    text-align: right;
`

const GreenCheck = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
`

const BankAccount = ({ bankAccount, handlePrimarySelect }) => {
    const { id, meta: { institution, account }, payment_method } = bankAccount;
    return (
        <BankAccountWrapper p="10px 40px" justify="space-between" centerAlign>
            <BankName gap="10" centerAlign>
                <BankIcon src={bankIcon} alt="Bank icon" />
                <Text variant="h3">{institution.name}</Text>
            </BankName>
            <Text variant="h3" centered secondary>{account.name}</Text>
            <Flex justify="flex-end">
                {payment_method?.primary ? (
                    <PrimaryAccount gap="10" centered>
                        <GreenCheck src={greenCheckIcon} alt="Green check" />
                        <GreenText bold>Primary Account</GreenText>
                    </PrimaryAccount>
                ) : (
                    <SetAsPrimaryButton
                        size="small"
                        onClick={() => handlePrimarySelect(id)}
                        disabled={account.subtype !== "checking"}
                    >
                        Set As Primary
                    </SetAsPrimaryButton>
                )}
            </Flex>
        </BankAccountWrapper>
    )
}

export default BankAccount
