import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import closeIcon from "assets/icons/circleX.svg";
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Logo from 'components/Logo';
import Button from 'components/Button';
import { downloadBlob, getFilenameFromHeaders } from 'util/helpers';
import callApi from 'api';

const ViewInvoiceWrapper = styled(Flex)`
  width: 600px;
  padding: 70px;
  position: relative;
`

const InvoiceInformation = styled(Flex)`
  width: 100%;
  margin: 20px 0;
  & > div {
    justify-content: space-between;
  }
`

const CloseIcon = styled.img`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 25px;
  height: 25px;
  cursor: pointer;
`

const InvoiceDetailsModal = ({ invoiceDetails, onRequestClose, ...rest }) => {
    const [downloading, setDownloading] = useState(false);
    const { billedTo, billedFrom, billedOn, dueDate, paymentRequestId } =
        useMemo(() => invoiceDetails ?? {}, [invoiceDetails]);
    const downloadInvoice = useCallback(async () => {
        setDownloading(true);
        const response = await callApi(`/payment-request/${paymentRequestId}/pdf`);
        const filename = getFilenameFromHeaders(response.headers);
        const blob = await response.blob();
        downloadBlob(blob, filename);
        setDownloading(false);
      }, [paymentRequestId]);
    return (
        <Modal onRequestClose={onRequestClose} {...rest}>
            <ViewInvoiceWrapper gap="30" column centered>
                <CloseIcon src={closeIcon} alt="Close icon" onClick={onRequestClose} />
                <Logo width="180px" />
                <InvoiceInformation gap="40" column>
                    <Flex centerAlign>
                        <Text>Billed To:</Text>
                        <Text>{billedTo}</Text>
                    </Flex>
                    <Flex centerAlign>
                        <Text>Billed From:</Text>
                        <Text>{billedFrom}</Text>
                    </Flex>
                    <Flex centerAlign>
                        <Text>Billed On:</Text>
                        <Text>{billedOn}</Text>
                    </Flex>
                    <Flex centerAlign>
                        <Text>Due Date:</Text>
                        <Text>{dueDate}</Text>
                    </Flex>
                </InvoiceInformation>
                <Button size="full" onClick={downloadInvoice} loading={downloading}>
                    Download PDF
                </Button>
                <Flex gap="10" centerAlign>
                    <Text variant="p2">Powered by</Text>
                    <Logo width="90px" />
                </Flex>
            </ViewInvoiceWrapper>
        </Modal>
    )
}

export default InvoiceDetailsModal
