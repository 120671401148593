import styled from "@emotion/styled";
import callApi from "api";
import userIcon from "assets/icons/user.svg";
import { errorToast } from "common/toasts";
import Button from "components/Button";
import CurrencyInput from "components/CurrencyInput";
import DateInput from "components/DateInput";
import Flex from "components/Flex";
import PageSection from "components/PageSection";
import TextInput from "components/TextInput";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Breadcrumbs from "routing/Breadcrumbs";
import { currentUser, refreshUser } from "user/data";
import TextArea from "components/TextArea";
import PaymentDueDateFields from "paymentRequests/components/PaymentDueDateFields";

const CreateCustomerForm = styled.form`
  display: grid;
  grid-template-rows: 1fr 1fr auto auto;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const TextAreaWrapper = styled.div`
  grid-column: 1 / span 2;
`;

const breadcrumbs = [
  {
    loc: "../customers",
    display: "Customers",
  },
  {
    display: `Create`,
  },
];

const CreateCustomer = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, errors } = useForm();
  const setCurrUser = useSetRecoilState(currentUser);
  const currentInvoiceDate = watch("invoice_date");
  const onSubmit = useCallback(
    async ({ payment_amount, invoice_date, ...customerData }) => {
      const response = await callApi("/organizations/customer", "POST", {
        ...customerData,
        payment_amount: payment_amount ? payment_amount * 100 : null,
        invoice_date: invoice_date ? `${invoice_date}T00:00` : null,
      });
      const data = await response.json();
      if (response.ok) {
        refreshUser(setCurrUser);
        navigate("../");
      } else {
        errorToast(data?.detail);
      }
    },
    [navigate, setCurrUser]
  );

  return (
    <>
      <Breadcrumbs breadcrumbList={breadcrumbs} />
      <Flex column gap="30" p="20px 0">
        <PageSection icon={userIcon} title="Add a customer">
          <CreateCustomerForm onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label="Customer Name"
              inputRef={() => register("customer_name", { required: true })}
              placeholder="i.e. Matt's Masks"
              errors={errors}
              showLabel
            />
            <TextInput
              label="Customer Email"
              inputRef={() => register("customer_email", { required: true })}
              placeholder="i.e. matt@mattsmasks.co"
              errors={errors}
              showLabel
            />
            <CurrencyInput
              label="Payment Amount (optional)"
              inputRef={() => register("payment_amount")}
              placeholder="0.00"
              watch={watch}
              errors={errors}
            />
            <DateInput
              label="Invoice Date (optional)"
              inputRef={() => register("invoice_date")}
              watch={watch}
              errors={errors}
            />
            <PaymentDueDateFields
              view="customer"
              invoiceDate={currentInvoiceDate}
            />
            <TextAreaWrapper>
              <TextArea
                label="Memo (optional)"
                inputRef={() => register("memo")}
                errors={errors}
                placeholder="i.e. For service X and Y"
              />
            </TextAreaWrapper>
            <Flex gap="20" centerAlign>
              <Button size="small" type="submit">
                Request
              </Button>
              <Button variant="tertiary" onClick={() => navigate("../")}>
                Cancel
              </Button>
            </Flex>
          </CreateCustomerForm>
        </PageSection>
      </Flex>
    </>
  );
};

export default CreateCustomer;
