import callApi from "api";
import { atom, selector } from "recoil";

const fetchPaymentsAndRequestsEffect = ({ setSelf, trigger }) => {
    if (trigger === "get") {
        setSelf(async () => {
            const response = await callApi("/loan");
            const data = await response.json();
            return data;
        });
    }
};

export const refreshPaymentsAndRequests = async (setPayments) => {
    const response = await callApi("/loan");
    const data = await response.json();
    setPayments(data);
};

export const paymentsAndRequestsState = atom({
    key: "PaymentsAndRequestsState",
    default: {},
    effects: [fetchPaymentsAndRequestsEffect],
});

export const paymentState = selector({
    key: "PaymentsState",
    get: ({ get }) => get(paymentsAndRequestsState).data,
});

export const incompleteRequestsState = selector({
    key: "IncompleteRequestsState",
    get: ({ get }) => get(paymentsAndRequestsState).incomplete_requests,
});

const fetchPaymentOptionsEffect = ({ setSelf, trigger }) => {
    const fetchPaymentOptions = async () => {
        const response = await callApi("/loan/options");
        if (response.ok) {
            const { data } = await response.json();
            setSelf(data);
        }
    };
    if (trigger === "get") {
        fetchPaymentOptions();
    }
};

export const paymentOptionsState = atom({
    key: "PaymentOptionsState",
    default: [],
    effects: [fetchPaymentOptionsEffect],
});

export const selectedPaymentOptionState = atom({
    key: "SelectedPaymentOptionState",
    default: {},
})
