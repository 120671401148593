import styled from "@emotion/styled";
import Card from "./Card";
import Flex from "./Flex";
import Text from "./Text";

const TileWrapper = styled(Flex)`
  ${({ forcedWidth }) =>
    forcedWidth ? `width: ${forcedWidth}px;` : `min-width: 600px;`}
  height: fit-content;
  border-radius: 20px;
`;

const Tile = ({ children, forcedWidth = null, title, subtitle }) => {
  return (
    <Card shadow>
      <TileWrapper column centered forcedWidth={forcedWidth}>
        {title && (
          <Flex m="0 0 28px 0" gap="10" column centered>
            <Text variant="h1">
              {title}
            </Text>
            {subtitle && (
              <Text secondary centered>
                {subtitle}
              </Text>
            )}
          </Flex>
        )}
        {children}
      </TileWrapper>
    </Card>
  );
};

export default Tile;
