import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import Table from "components/Table";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button";
import { useMemo, useState } from "react";
import { currentUser } from "user/data";
import PageSection from "components/PageSection";
import Flex from "components/Flex";
import userIcon from "assets/icons/user.svg";
import Text from "components/Text";
import Modal from 'components/Modal';
import CSVUploader from 'components/CSVUploader';

const UserIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const ButtonLink = styled(Link)`
  color: var(--color-black);
  font-weight: 500;
  padding: 10px 0;
`;

const UploadCSVModalWrapper = styled(Flex)`
    padding: 30px;
    max-width: 800px;
`

const UploadCSVButton = styled(Button)`
    border: 1px solid var(--color-black);
    border-radius: 7px;
    & > img {
        width: 20px;
        height: 20px;
    }
`

const customerColumns = [
  {
    header: "Customer Name",
    id: "customer_name",
    accessorFn: (row) => row.name ?? row.legal_name,
  },
  {
    header: "Customer Email",
    id: "customer_email",
    accessorFn: (row) => row.user?.email,
  },
  {
    header: "Customer Details",
    accessorKey: "id",
    cell: (value) => (
      <ButtonLink to={`../customers/${value.getValue()}`}>
        View Details
      </ButtonLink>
    ),
  },
];

const Customers = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const currUser = useRecoilValue(currentUser);
  const customers = useMemo(
    () => currUser?.organization?.customers ?? [],
    [currUser]
  );
  return (
    <>
      <PageSection
        title="Current Customers"
        controls={
          customers?.length ? (
            <Flex gap="10" centerAlign>
              <Button
                size="small"
                onClick={() => {
                  navigate("create");
                }}
              >
                Add Customer
              </Button>
              <Button size="small" variant="outline" onClick={() => setModalOpen(true)}>
                Bulk Upload
              </Button>
            </Flex>
          ) : null
        }
        tableSection
      >
        {customers?.length ? (
          <Table columns={customerColumns} data={customers} />
        ) : (
          <Flex gap="10" p="20px" column centered>
            <UserIcon src={userIcon} alt="User icon" />
            <Text variant="h2">Create a new customer to get started</Text>
            <Flex gap="10" centerAlign>
              <Button
                size="medium"
                onClick={() => {
                  navigate("create");
                }}
              >
                Create Customer
              </Button>
              <Text variant="p1" secondary>or</Text>
              <UploadCSVButton size="medium" variant="outline" onClick={() => setModalOpen(true)}>
                Bulk Upload
              </UploadCSVButton>
            </Flex>
          </Flex>
        )}
      </PageSection>
      <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <UploadCSVModalWrapper column centered gap="20">
          <CSVUploader />
        </UploadCSVModalWrapper>
      </Modal>
    </>
  );
};

export default Customers;
