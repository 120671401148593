import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "@emotion/styled";
import { selectedPaymentOptionState } from "payments/data";
import { selectedPaymentRequestState } from "paymentRequests/data";
import Button from "components/Button";
import Flex from "components/Flex";
import Text from "components/Text";
import { formatCurrency } from "util/helpers";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

const PaymentOptionWrapper = styled(Flex)`
  width: 330px;
  border: 1px solid var(--color-medium-light-gray);
  box-shadow: 0px 50px 50px -40px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  :hover {
    background-color: var(--color-white);
  }
  & > div:first-of-type {
    background-color: ${({ bgColor }) => bgColor};
    padding: 30px 20px;
    width: 100%;
    text-align: center;
  }
  & > div > div {
    max-width: 60%;
  }
  & * {
    cursor: pointer;
    padding: auto 20px;
  }
`;

const PaymentOptionText = styled(Flex)`
  width: 70%;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-medium-light-gray);
  }
  padding: 10px 0 20px;
`;

const PaymentOption = ({ loanOption, color }) => {
  const navigate = useNavigate();
  const { fee_percentage, number_of_days } = useMemo(
    () => loanOption ?? {},
    [loanOption]
  );
  const paymentRequest = useRecoilValue(selectedPaymentRequestState);
  const setSelectedPaymentOption = useSetRecoilState(
    selectedPaymentOptionState
  );
  const paymentMessage = useMemo(() => {
    if (number_of_days === 0) {
      return "No Extension";
    } else {
      return `${number_of_days} Day Extension`;
    }
  }, [number_of_days]);
  const handleSelect = useCallback(() => {
    setSelectedPaymentOption(loanOption);
    navigate("review");
  }, [setSelectedPaymentOption, loanOption, navigate]);
  return (
    <PaymentOptionWrapper
      gap="10"
      m="10px 0"
      p="0 0 20px"
      bgColor={color}
      column
      centerAlign
    >
      <Text variant="h3">{paymentMessage}</Text>
      <PaymentOptionText centered>
        <Text variant="p2" secondary>
          {fee_percentage}% Fee
        </Text>
      </PaymentOptionText>
      <PaymentOptionText centered>
        <Text variant="p2" secondary>
          {formatCurrency(
            paymentRequest?.payment_amount +
              (fee_percentage * paymentRequest?.payment_amount) / 100
          )}{" "}
          Total (including Fee)
        </Text>
      </PaymentOptionText>
      <PaymentOptionText centered>
        <Text variant="p2" secondary>
          Due on{" "}
          {dayjs(paymentRequest?.payment_due_date)
            .add(number_of_days, "d")
            .format("MM/DD/YYYY")}
        </Text>
      </PaymentOptionText>
      <Button size="large" onClick={handleSelect}>
        Select Payment Option
      </Button>
    </PaymentOptionWrapper>
  );
};

export default PaymentOption;
