import styled from '@emotion/styled'
import Flex from './Flex'

const TextAreaInput = styled.textarea`
    all: unset;
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '188px'};
    box-sizing: border-box;
    border: 1px solid transparent;
    resize: none;
    background-color: var(--color-snow-white);
    padding: 12px 10px;
    transition: border-color 0.2s ease;
    border-radius: 6px;
    ::placeholder {
        color: var(--color-medium-light-gray);
        font-size: 16px;
    }
`

const Label = styled.label`
    font-size: 14px;
    font-weight: 200;
    color: var(--color-dark-gray);
`

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 10px;
    font-style: italic;
    left: 0;
    bottom: 6px;
    position: absolute;
`

const TextArea = ({
    inputRef = null,
    name,
    label,
    errors,
    ...rest
}) => (
    <Flex gap="2" column>
        {label && <Label htmlFor={inputRef ? inputRef().name : name}>{label}</Label>}
        {inputRef ? <TextAreaInput {...inputRef()} {...rest} /> : <TextAreaInput name={name} {...rest} />}
        {errors && errors[name] && <ErrorMessage>{errors[name].message}</ErrorMessage>}
    </Flex>
)

export default TextArea
