import { errorToast } from "common/toasts";
import { isStrongPassword } from "validator";
import { PW_STRENGTH_ERROR } from "./constants";

const pwOptions = {
  minLength: 10,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 0,
  returnScore: false,
};

export const validateStrongPW = (input) => {
  if (!isStrongPassword(input, pwOptions)) {
    errorToast(PW_STRENGTH_ERROR);
    return "Invalid password";
  }
  return true;
};

export const getCurrencyString = (num) =>
  num.toLocaleString("en-US", { style: "currency", currency: "USD" });

export const formatCurrency = (num) =>
  getCurrencyString(num ? num / 10 ** 2 : 0);

export const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export const getFilenameFromHeaders = (headers) =>
  headers.get("Content-Disposition").split(";")[1].split("=")[1].split('"')[1];
