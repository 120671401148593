const theme = {
    h1: {
        fontSize: '32px',
        lineHeight: '36px',
        fontWeight: 700,
    },
    h2: {
        fontSize: '24px',
        lineHeight: '26px',
        fontWeight: 600,
    },
    h3: {
        fontSize: '18px',
        lineHeight: '20px',
        fontWeight: 600,
    },
    p1: {
        fontSize: '16px',
        lineHeight: '18px',
        fontWeight: 300,
    },
    p2: {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 300,
    },
    d: {
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 500,
    },
}

export default theme