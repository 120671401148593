import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import callApi from "api";
import Flex from "components/Flex";
import Modal from "components/Modal";
import closeIcon from "assets/icons/circleX.svg";
import CurrencyInput from "components/CurrencyInput";
import DateInput from "components/DateInput";
import Text from "components/Text";
import { errorToast } from "common/toasts";
import { useCallback, useEffect } from "react";
import Button from "components/Button";
import TextArea from "components/TextArea";
import PaymentDueDateFields from "./PaymentDueDateFields";

const ModalWrapper = styled(Flex)`
  padding: 20px;
  position: relative;

  & > form {
    margin: 30px 0 10px;
    width: 400px;
    display: flex;
    flex-direction: column;
  }
  textarea {
    margin-bottom: 30px;
  }
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease;
  :hover {
    opacity: 0.7;
  }
`;

const customerNotOnboardedError = "Customer has not been onboarded yet.";

const RequestPaymentModal = ({
  isOpen,
  onRequestClose,
  refresh,
  customerId,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const requestPayment = useCallback(
    async ({ payment_amount, invoice_date, memo }) => {
      const response = await callApi(
        `/payment-request/customer/${customerId}`,
        "POST",
        {
          payment_amount: payment_amount * 100,
          invoice_date: `${invoice_date}T00:00`,
          memo,
        }
      );
      if (response.ok) {
        onRequestClose();
        refresh();
      } else {
        const { detail: errorMessage } = await response.json();
        errorToast(
          errorMessage === customerNotOnboardedError
            ? "Customer has not onboarded yet, please wait for them to complete the initial payment request before requesting another. If this is an issue please reach out to support@trust.co"
            : "An error occurred when creating the payment request."
        );
      }
    },
    [onRequestClose, refresh, customerId]
  );
  const currentInvoiceDate = watch("invoice_date");

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalWrapper column>
        <CloseIcon
          src={closeIcon}
          alt="Close button"
          onClick={onRequestClose}
        />
        <Text variant="h2">Request Payment</Text>
        <form onSubmit={handleSubmit(requestPayment)}>
          <CurrencyInput
            label="Payment Amount"
            inputRef={() => register("payment_amount", { required: true })}
            placeholder="0.00"
            watch={watch}
            errors={errors}
          />
          <DateInput
            label="Invoice Date"
            inputRef={() => register("invoice_date", { required: true })}
            watch={watch}
            errors={errors}
          />
          <PaymentDueDateFields invoiceDate={currentInvoiceDate} />
          <TextArea inputRef={() => register("memo")} label="Memo (optional)" />
          <Button size="full" type="submit">
            Request
          </Button>
        </form>
      </ModalWrapper>
    </Modal>
  );
};

export default RequestPaymentModal;
