import { paymentRequestState } from 'paymentRequests/data'
import { useEffect } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

const NewRequest = () => {
    const { paymentRequestId } = useParams();
    const setPaymentRequest = useSetRecoilState(paymentRequestState);
    useEffect(() => {
        setPaymentRequest({ id: paymentRequestId });
    }, [setPaymentRequest, paymentRequestId]);
    return <Outlet />;
};

export default NewRequest;
