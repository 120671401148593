import Login from "views/Login";
import Customers from "views/vendor/Customers";
import CustomerDetails from "views/vendor/CustomerDetails";
import RequestPayment from "views/vendor/RequestPayment";
import Payments from "views/customer/Payments";
import { Navigate } from "react-router-dom";
import { selector } from "recoil";
import { userOrgType } from "user/data";
import { ORGANIZATION_TYPE } from "common/enums";
import personIcon from "assets/person-icon.svg";
import cardIcon from "assets/card-icon.svg";
import requestIcon from "assets/request-icon.svg";
import settingsIcon from "assets/settings-icon.svg";
import VerifyUser from "views/customer/onboarding/VerifyUser";
import Onboarding from "views/customer/onboarding/Onboarding";
import CreateCustomer from "views/vendor/CreateCustomer";
import ApplicationDenied from "views/customer/onboarding/ApplicationDenied";
import BusinessDetails from "views/customer/onboarding/BusinessDetails";
import ConnectPlaid from "views/customer/onboarding/ConnectPlaid";
import CustomerSignUp from "views/customer/onboarding/CustomerSignUp";
import PaymentOptions from "views/customer/newPaymentRequest/PaymentOptions";
import UnderwritingCheck from "views/customer/onboarding/UnderwritingCheck";
import VerifyAccount from "views/customer/onboarding/VerifyAccount";
import PaymentDetails from "views/customer/PaymentDetails";
import TermsAcceptance from "views/customer/newPaymentRequest/TermsAcceptance";
import NewRequest from "views/customer/newPaymentRequest/NewRequest";
import Requests from "views/customer/Requests";
import PaymentRequests from "views/vendor/PaymentRequests";
import TermsAndConditions from 'views/TermsAndConditions';
import Settings from 'views/customer/Settings';
import PaymentRequestDetails from 'views/vendor/PaymentRequestDetails';
import PasswordResetRequest from 'views/PasswordResetRequest';
import PasswordReset from 'views/PasswordReset';

export const ROUTES = {
  customers: {
    path: "customers",
    label: "Customers",
    element: <Customers />,
    icon: personIcon,
    showInNav: true,
  },
  login: {
    path: "login",
    label: "Login",
    element: <Login />,
    showInNav: false,
  },
  passwordReset: {
    path: "password-reset/:token",
    label: "Password Reset",
    element: <PasswordReset />,
    showInNav: false,
  },
  requestPasswordReset: {
    path: "request-password-reset",
    label: "Request Password Reset",
    element: <PasswordResetRequest />,
    showInNav: false,
  },
  payments: {
    path: "payments",
    element: <Payments />,
    icon: cardIcon,
    label: "Payments",
    showInNav: true,
  },
  paymentDetails: {
    path: "payments/:paymentId",
    element: <PaymentDetails />,
    label: "Payment Details",
    showInNav: false,
  },
  requests: {
    path: "requests",
    element: <Requests />,
    icon: requestIcon,
    label: "Requests",
    showInNav: true,
  },
  paymentRequests: {
    path: "payments",
    element: <PaymentRequests />,
    icon: cardIcon,
    label: "Payments",
    showInNav: true,
  },
  requestPayment: {
    path: "customers/:customerId/request-payment",
    element: <RequestPayment />,
    label: "Request Payment",
    showInNav: false,
  },
  paymentRequestDetails: {
    path: "customers/:customerId/payment-request/:paymentRequestId/details",
    element: <PaymentRequestDetails />,
    label: "Payment Request Details",
    showInNav: false,
  },
  customerDetails: {
    path: "customers/:customerId",
    label: "Customer Details",
    element: <CustomerDetails />,
    showInNav: false,
  },
  createCustomer: {
    path: "customers/create",
    element: <CreateCustomer />,
    showInNav: false,
    label: "Create Customer",
  },
  verifyUser: {
    path: "verify-user/:token",
    element: <VerifyUser />,
    label: "Verify User",
    showInNav: false,
  },
  termsAndConditions: {
    path: "terms-and-conditions",
    element: <TermsAndConditions />,
    label: "Terms And Conditions",
    showInNav: false,
  },
  settings: {
    path: "settings",
    element: <Settings />,
    label: "Settings",
    icon: settingsIcon,
    showInNav: true,
  },
  onboarding: {
    path: "onboarding",
    element: <Onboarding />,
    label: null,
    showInNav: false,
    subRoutes: [
      {
        path: "customer-details",
        element: <CustomerSignUp />,
        label: null,
        showInNav: false,
      },
      {
        path: "business-details",
        element: <BusinessDetails />,
        label: null,
        showInNav: false,
      },
      {
        path: "connect-plaid",
        element: <ConnectPlaid />,
        label: null,
        showInNav: false,
      },
      {
        path: "underwriting-check",
        element: <UnderwritingCheck />,
        label: null,
        showInNav: false,
      },
      {
        path: "application-denied",
        element: <ApplicationDenied />,
        label: null,
        showInNav: false,
      },
      {
        path: "verify-account",
        element: <VerifyAccount />,
        label: null,
        showInNav: false,
      },
    ],
  },
  newRequest: {
    path: "new-request/:paymentRequestId",
    element: <NewRequest />,
    label: null,
    showInNav: false,
    subRoutes: [
      {
        path: "review",
        element: <TermsAcceptance />,
        label: null,
        showInNav: false,
      },
      {
        path: "",
        element: <PaymentOptions />,
        label: null,
        showInNav: false,
      },
      {
        path: "*",
        element: <PaymentOptions />,
        label: null,
        showInNav: false,
      },
    ],
  },
};

const vendorRoutes = [
  ROUTES.paymentRequestDetails,
  ROUTES.customerDetails,
  ROUTES.customers,
  ROUTES.paymentRequests,
  ROUTES.createCustomer,
  ROUTES.requestPayment,
  {
    path: "*",
    element: <Navigate to="customers" />,
    label: "Vendor Redirect",
    showInNav: false,
  },
];

const customerRoutes = [
  ROUTES.onboarding,
  ROUTES.newRequest,
  ROUTES.payments,
  ROUTES.paymentDetails,
  ROUTES.requests,
  ROUTES.settings,
  {
    path: "*",
    element: <Navigate to="payments" />,
    label: "Customer Redirect",
    showInNav: false,
  },
];

const sharedRoutes = [
  ROUTES.termsAndConditions,
  ROUTES.passwordReset,
];

const unauthenticatedRoutes = [
  ROUTES.login,
  ROUTES.verifyUser,
  ROUTES.passwordReset,
  ROUTES.requestPasswordReset,
  {
    path: "*",
    element: <Navigate to="login" />,
    label: "Unauthenticated Redirect",
    showInNav: false,
  },
];

export const availableRoutes = selector({
  key: "AvailableRoutes",
  get: ({ get }) => {
    const currentOrgType = get(userOrgType);

    switch (currentOrgType) {
      case ORGANIZATION_TYPE.VENDOR:
        return [...vendorRoutes, ...sharedRoutes];
      case ORGANIZATION_TYPE.CUSTOMER:
        return [...customerRoutes, ...sharedRoutes];
      default:
        return unauthenticatedRoutes;
    }
  },
});
