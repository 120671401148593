import styled from '@emotion/styled'
import { paymentStatusMap } from 'common/constants'
import Button from 'components/Button'
import Flex from 'components/Flex'
import PageSection from 'components/PageSection'
import Text from 'components/Text'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import InvoiceDetailsModal from 'paymentRequests/components/InvoiceDetailsModal'
import PaymentTermsAgreement from 'payments/components/PaymentTermsAgreement'
import { paymentState } from 'payments/data'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Breadcrumbs from 'routing/Breadcrumbs'
import { selectedTermsAcceptanceObjectState, termsAcceptancePaymentRequestIdState } from 'termsAcceptance/data'
import { formatCurrency } from 'util/helpers'

const PaymentDetailWrapper = styled(Flex)`
    & > div {
        width: 48%;
    }
`

const PaymentDetailRow = styled(Flex)`
    padding: 20px 0;
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-medium-light-gray);
    }
    & > div:last-of-type {
        max-width: 400px;
        text-align: right;
    }
`

const TextboxWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid var(--color-light-gray);
    overflow: hidden;
`

const Textbox = styled(Flex)`
    width: 100%;
    height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px;
    border-radius: 10px;    
`

const BoldText = styled.span`
    font-weight: 700;
`;

const TermSummaryRow = styled(Flex)`
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-light-gray);
`;

const PaymentDetails = () => {
    const { paymentId } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const payments = useRecoilValue(paymentState);
    const setTermsAcceptanceRequestId = useSetRecoilState(termsAcceptancePaymentRequestIdState);
    const selectedTermsAcceptance = useRecoilValue(selectedTermsAcceptanceObjectState);
    const {
        effective_date,
        invoiced_amount,
        invoice_due_date,
        fee,
        payment_length,
        extended_payment_due_date,
        extended_payment_amount,
        customer_legal_name,
        customer_jurisdiction,
        customer_entity_type,
        vendor_legal_name,
        vendor_memo,
    } = useMemo(() => selectedTermsAcceptance ?? {}, [selectedTermsAcceptance]);
    const {
        id,
        status,
        payment_request,
    } = useMemo(() => payments.find((p) => p.id === paymentId) ?? {}, [payments, paymentId]);
    const breadcrumbs = useMemo(() => [
        {
            loc: '../',
            display: 'Payments',
        },
        {
            display: paymentId,
        },
    ], [paymentId]);
    useEffect(() => {
        setTermsAcceptanceRequestId(payment_request.id);
    }, [setTermsAcceptanceRequestId, payment_request]);
    return (
        <>
            <Breadcrumbs breadcrumbList={breadcrumbs} />
            <Flex column gap="30" p="20px 0">
                <PageSection>
                    <PaymentDetailWrapper justify="space-between">
                        <Flex column>
                            <Text variant="h2">Payment Terms Details</Text>
                            <PaymentDetailRow justify="space-between">
                                <Text variant="h3">Invoiced Amount</Text>
                                <Text variant="p1">
                                    {formatCurrency(invoiced_amount)}
                                </Text>
                            </PaymentDetailRow>
                            <PaymentDetailRow justify="space-between">
                                <Text variant="h3">Fee to Extend with Market</Text>
                                <Text variant="p1">
                                    {formatCurrency((fee * invoiced_amount) / 100)}
                                </Text>
                            </PaymentDetailRow>
                            <PaymentDetailRow justify="space-between">
                                <Text variant="h3">Amount Owed</Text>
                                <Text variant="p1">
                                    {formatCurrency(extended_payment_amount)}
                                </Text>
                            </PaymentDetailRow>
                            <PaymentDetailRow justify="space-between">
                                <Text variant="h3">Date Started</Text>
                                <Text variant="p1">{dayjs(effective_date).format("YYYY/MM/DD")}</Text>
                            </PaymentDetailRow>
                            <PaymentDetailRow justify="space-between">
                                <Text variant="h3">Date Due</Text>
                                <Text variant="p1">{dayjs(extended_payment_due_date).format("YYYY/MM/DD")}</Text>
                            </PaymentDetailRow>
                            <PaymentDetailRow justify="space-between">
                                <Text variant="h3">Status</Text>
                                <Text variant="p1">{paymentStatusMap[status]}</Text>
                            </PaymentDetailRow>
                            <PaymentDetailRow justify="space-between">
                                <Text variant="h3">Market Payment ID</Text>
                                <Text variant="p1">{id}</Text>
                            </PaymentDetailRow>
                            <PaymentDetailRow justify="space-between">
                                <Text variant="h3">Memo</Text>
                                <Text variant="p1" secondary={!vendor_memo}>{vendor_memo ?? 'N/A'}</Text>
                            </PaymentDetailRow>
                            <Button size="full" onClick={() => setModalOpen(true)}>View Invoice</Button>
                        </Flex>
                        <Flex gap="10" column>
                            <Text variant="h2">Extended Payment Terms Agreement</Text>
                            <TextboxWrapper>
                                <Textbox column>
                                    <Text variant="h2" centered>SUMMARY OF TERMS</Text>
                                    <TermSummaryRow>
                                        <Text variant="p2">Effective Date<br />(“<BoldText>Effective Date</BoldText>”):</Text>
                                        <Text variant="p2">{dayjs().format("YYYY/MM/DD")}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Customer Legal Name<br />(“<BoldText>Customer</BoldText>”):</Text>
                                        <Text variant="p2">{customer_legal_name}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Customer Jurisdiction of Formation/Incorporation:</Text>
                                        <Text variant="p2">{customer_jurisdiction}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Customer Entity Type:</Text>
                                        <Text variant="p2">{capitalize(customer_entity_type)}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Vendor Legal Name<br />(“<BoldText>Vendor</BoldText>”):</Text>
                                        <Text variant="p2">{vendor_legal_name}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Invoiced Amount<br />(“<BoldText>Invoiced Amount</BoldText>”):</Text>
                                        <Text variant="p2">{formatCurrency(invoiced_amount)}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Invoice Date<br />(“<BoldText>Invoice Date</BoldText>”):</Text>
                                        <Text variant="p2">{dayjs(payment_request.invoice_date).format("YYYY/MM/DD")}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Due Date of Payment to Vendor<br />(“<BoldText>Vendor Payment Date</BoldText>”):</Text>
                                        <Text variant="p2">{dayjs(invoice_due_date).format("YYYY/MM/DD")}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Length of the Payment Extension<br />(“<BoldText>Payment Extension Period</BoldText>”):</Text>
                                        <Text variant="p2">{payment_length} days</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Fee<br />(“<BoldText>Extension Fee</BoldText>”):</Text>
                                        <Text variant="p2">{formatCurrency((fee * invoiced_amount) / 100)}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Extended Payment Due Date<br />(“<BoldText>Extended Payment Due Date</BoldText>”):</Text>
                                        <Text variant="p2">{dayjs(extended_payment_due_date).format("YYYY/MM/DD")}</Text>
                                    </TermSummaryRow>
                                    <TermSummaryRow>
                                        <Text variant="p2">Extended Payment Amount<br />(“<BoldText>Extended Payment Amount</BoldText>”):</Text>
                                        <Text variant="p2">
                                            {formatCurrency(extended_payment_amount)}
                                        </Text>
                                    </TermSummaryRow>
                                    <PaymentTermsAgreement />
                                </Textbox>
                            </TextboxWrapper>
                        </Flex>
                    </PaymentDetailWrapper>
                </PageSection>
            </Flex>
            <InvoiceDetailsModal
                invoiceDetails={{
                    billedTo: customer_legal_name,
                    billedFrom: vendor_legal_name,
                    billedOn: dayjs(effective_date).format("MMMM D, YYYY"),
                    dueDate: dayjs(invoice_due_date).format("MMMM D, YYYY"),
                    paymentRequestId: payment_request.id,
                }}
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
            />
        </>
    );
};

export default PaymentDetails;
