import styled from "@emotion/styled";
import bg from "assets/bg.png";
import Sidebar from "./Sidebar";
import { Toaster } from 'react-hot-toast';
import Body from "./Body";
import { useRecoilValue } from "recoil";
import { userActive } from "user/data";
import Header from "./Header";
import Logo from "./Logo";
import Footer from './Footer';

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-${({ showActiveLayout }) =>
    showActiveLayout ? "columns: auto" : "rows: auto 1fr"};
  min-height: 100%;
  width: 100%;
`;

const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  z-index: -2;
  background: url(${bg});
  background-size: cover;
`

const MainColumn = styled.div`
  padding: 0 36px 0 36px;
  margin: 0 auto;
  display: grid;
  width: 1200px;
  grid-template-rows: auto 1fr auto;
`;
const SpacedLogo = styled(Logo)`
  margin: 40px 32px;
`;

const Layout = ({ children }) => {
  const showActiveLayout = useRecoilValue(userActive);
  return (
    <Grid>
      <Background />
      <Toaster position="top-right" />
      {!showActiveLayout && <SpacedLogo />}
      {showActiveLayout && <Sidebar />}
      <MainColumn>
        {showActiveLayout && <Header />}
        <Body activeLayout={showActiveLayout}>{children}</Body>
        <Footer />
      </MainColumn>
    </Grid>
  );
};
export default Layout;
