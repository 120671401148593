import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import Text from "components/Text";

const MainNavItem = styled(NavLink)`
  display: flex;
  padding: 20px;
  position: relative;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 24px;
  background: transparent;
  color: var(--color-black);
  letter-spacing: 1px;
  &:hover {
    background: var(--color-snow-white);
  }
  &.active {
    background: var(--color-snow-white);
  }
`;

const SidebarIcon = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
  margin-left: 9px;
`;

const NavItemText = styled(Text)`
  margin-left: 10px;
`;

const NavItem = ({ path, icon, label, expanded, ...rest }) => {
  return (
    <MainNavItem to={path} {...rest}>
      <SidebarIcon src={icon} alt={label} expanded={expanded} />
      {expanded && (
        <NavItemText variant="p2" bold>
          {label}
        </NavItemText>
      )}
    </MainNavItem>
  );
};

NavItem.propTypes = {
  path: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  expanded: PropTypes.bool,
};

export default NavItem;
