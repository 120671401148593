import PageSection from 'components/PageSection';
import Text from 'components/Text';
import CustomerPaymentRequest from 'paymentRequests/components/CustomerPaymentRequest';
import { incompleteRequestsState, paymentsAndRequestsState, refreshPaymentsAndRequests } from 'payments/data';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const Requests = () => {
    const requests = useRecoilValue(incompleteRequestsState);
    const setPaymentsAndRequests = useSetRecoilState(paymentsAndRequestsState);
    useEffect(() => {
        refreshPaymentsAndRequests(setPaymentsAndRequests);
    }, [setPaymentsAndRequests]);

    return (
        <PageSection title="Current Requests" tableSection>
            {requests?.length ? requests?.map((r) => <CustomerPaymentRequest key={r.id} request={r} />) : (
                <Text p="0 20px 20px" centered>You currently have no new requests.</Text>
            )}
        </PageSection>
    );
};

export default Requests;