import { useRecoilState, useRecoilValue } from 'recoil';
import Button from 'components/Button';
import PageSection from 'components/PageSection';
import { useCallback, useEffect, useState } from 'react';
import callApi from 'api';
import { currentUser } from 'user/data';
import { BANK_ACCOUNT_ROUTES } from 'bankAccounts/api';
import { ONBOARDING, PLAID_CONTEXT, PLAID_LINK_MODE, PLAID_LINK_TOKEN } from 'common/constants';
import { errorToast, successToast } from 'common/toasts';
import { usePlaidLink } from 'react-plaid-link';
import Loader from 'components/Loader';
import BankAccount from 'bankAccounts/components/BankAccount';
import Flex from 'components/Flex';
import { bankAccountsState, refreshBankAccounts } from 'bankAccounts/data';

const Settings = () => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState("");
    const { organization } = useRecoilValue(currentUser);
    const [bankAccounts, setBankAccounts] = useRecoilState(bankAccountsState);
    const onSuccess = useCallback(
        async (publicToken, meta) => {
            setLoading(true);
            const payload = {
                public_token: publicToken,
                meta,
                organization_id: organization?.id,
            };
            const response = await callApi(BANK_ACCOUNT_ROUTES.ROOT, "POST", payload);
            if (response.ok) {
                refreshBankAccounts(setBankAccounts);
                setLoading(false);
            } else {
                const data = await response.json();
                setLoading(false);
                errorToast(data.detail);
            }
        },
        [
            organization,
            setBankAccounts,
        ]
    );
    const handlePrimarySelect = useCallback(async (id) => {
        const response = await callApi(`/payment-methods/${id}/primary`, 'PATCH');
        if (response.ok) {
            refreshBankAccounts(setBankAccounts);
            successToast('Payment method successfully set as primary.');
        } else {
            const data = await response.json();
            errorToast(data?.detail ?? 'An error occurred when setting this method to primary.');
        }
    }, [setBankAccounts]);
    const { open, ready } = usePlaidLink({ token, onSuccess });
    const setStorageItems = useCallback((linkToken, plaidMode) => {
        localStorage.setItem(PLAID_LINK_TOKEN, linkToken);
        localStorage.setItem(PLAID_LINK_MODE, plaidMode);
        localStorage.setItem(PLAID_CONTEXT, ONBOARDING);
    }, []);
    useEffect(() => {
        const fetchToken = async () => {
            const response = await callApi(
                BANK_ACCOUNT_ROUTES.LINK_TOKEN,
                "POST",
                {}
            );
            const data = await response.json();
            setToken(data.link_token);
        };
        fetchToken();
    }, [bankAccounts]);
    if (loading) return <Loader />;
    return (
        <PageSection title="Select primary bank account">
            <Flex gap="10" m="0 0 10px" column>
                {bankAccounts.map((ba) => (
                    <BankAccount
                        key={ba.id}
                        bankAccount={ba}
                        handlePrimarySelect={handlePrimarySelect}
                    />
                ))}
            </Flex>
            <Button
                onClick={() => {
                    setStorageItems(token);
                    open();
                }}
                loading={!ready}
                size="full"
            >
                Add Bank Account
            </Button>
        </PageSection>
    );
};

export default Settings;
