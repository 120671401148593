import callApi from 'api';
import { errorToast } from 'common/toasts';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import Tile from 'components/Tile';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import greenCheckIcon from 'assets/icons/green-check.svg';
import Flex from 'components/Flex';
import Text from 'components/Text';
import styled from '@emotion/styled';

const GreenCheckIcon = styled.img`
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 60px;
    border: 4px solid var(--color-green);
    padding: 10px;
`;

const ResultWrapper = styled(Flex)`
    width: 300px;
`;

const PasswordResetRequest = () => {
    const [phase, setPhase] = useState('idle');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = useCallback(async ({ email }) => {
        setPhase('loading');
        const response = await callApi(`/users/request-password-reset?email=${encodeURIComponent(email)}`);
        if (response.ok) {
            setPhase('success');
        } else {
            errorToast('Something went wrong. Please try again.');
        }
    }, []);

    return (
        <Tile
            title="Reset your password"
            subtitle={
                phase === 'success'
                    ? null
                    : "Enter your email and we'll send you a password reset link"
            }
        >
            {phase !== 'success' ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextInput
                        name="email"
                        type="email"
                        label="Email"
                        inputRef={() =>
                            register('email', {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Please enter a valid email address.',
                                },
                            })
                        }
                        errors={errors}
                        showLabel
                    />
                    <Button
                        size="full"
                        onClick={handleSubmit(onSubmit)}
                        loading={phase === 'loading'}
                    >
                        Send Link
                    </Button>
                </form>
            ) : (
                <ResultWrapper gap="20" column centered>
                    <GreenCheckIcon src={greenCheckIcon} alt="Green check" />
                    <Text variant="h3" centered>
                        If you have an active Market account, you will receive an email shortly.
                    </Text>
                </ResultWrapper>
            )}
        </Tile>
    );
};

export default PasswordResetRequest;
