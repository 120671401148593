import { TOKEN_STORAGE_KEY } from "common/constants";

const baseUrl = process.env.REACT_APP_API_ENDPOINT || "http://localhost:8000";


const callApi = (url, method = "GET", body) => {
    const configuredHeaders = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(TOKEN_STORAGE_KEY)}`,
    });
    return fetch(`${baseUrl}${url}`, {
      method,
      body: body ? JSON.stringify(body) : null,
      headers: configuredHeaders,
    });
}

export default callApi;
