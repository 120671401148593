import styled from '@emotion/styled'
import Tile from 'components/Tile'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Button from 'components/Button'
import TextInput from 'components/TextInput'
// import { useRecoilState } from 'recoil'
// import { userOnboardingState } from 'user/data'

const accountInputs = [
    {
        name: 'account_holder_name',
        label: 'Account Holder Name',
        type: 'text',
        placeholder: 'Company Name',
    },
    {
        name: 'account_number',
        label: 'Account Number',
        type: 'password',
        placeholder: '**********',
    },
    {
        name: 'routing_number',
        label: 'Routing Number',
        type: 'password',
        placeholder: '*********',
    },
]


const BankForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`


const VerifyAccount = () => {
    const navigate = useNavigate()
    // const [onboardingState, setOnboardingState] = useRecoilState(userOnboardingState)
    const {
        register,
        handleSubmit,
    } = useForm()
    const onSubmit = (data) => {
        // console.log(data)
        // setOnboardingState({
        //     ...onboardingState,
        //     hasVerifiedACH: true,
        // })
        navigate('../../payments')
    }
    return (
        <Tile title="Verify Your Account">
            <BankForm onSubmit={handleSubmit(onSubmit)}>
                {accountInputs.map(({ label, type, placeholder, name }) => (
                    <TextInput
                        key={name}
                        name={name}
                        type={type}
                        label={label}
                        showLabel
                        placeholder={placeholder}
                        inputRef={() => register(name)}
                    />
                ))}
                <Button type="submit" size="medium">
                    Verify
                </Button>
            </BankForm>
        </Tile>
    )
}

export default VerifyAccount
