export const ORGANIZATION_TYPE = {
  VENDOR: 1,
  CUSTOMER: 2,
};

export const ORGANIZATION_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const LOAN_APPLICATION_STATUS = {
  NO_APPLICATION: 0,
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
};

export const PAYMENT_REQUEST_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  DENIED: 2,
};

export const PAYMENT_REQUEST_CUSTOMER_STATUS = {
  PENDING: 0,
  IN_PROGRESS: 1,
  AWAITING_ACH: 2,
  COMPLETED: 3,
};

export const PAYMENT_STATUS = {
  PENDING: 0,
  INITIATED: 1,
  COMPLETE: 2,
  FAILED: 3,
  DISPUTED: 4,
  REFUNDED: 5,
};
