import styled from '@emotion/styled'
import theme from '../util/styling'

const Text = styled.div`
    ${({ centered }) => centered && 'text-align: center;'}
    ${({ right }) => right && 'text-align: right;'}
    ${({ p }) => p && `padding: ${p};`}
  ${({ m }) => m && `margin: ${m};`}
  ${({ variant = 'p1', bold = false }) => `
    font-size: ${theme[variant].fontSize};
    line-height: ${theme[variant].lineHeight};
    font-weight: ${bold ? 700 : theme[variant].fontWeight};`}
    color: ${({ secondary, color = 'inherit' }) => (secondary ? 'var(--color-dark-gray)' : color)};
    letter-spacing: ${({ spacing = 'inherit' }) => spacing};
`

export default Text
