import dayjs from "dayjs";
import Flex from "components/Flex";
import Text from "components/Text";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { orgPaymentTerms } from "user/data";
import { useMemo } from "react";
import ConditionalWrapper from "components/ConditionalWrapper";

const Label = styled.div`
  font-size: 14px;
  font-weight: 200;
  color: var(--color-dark-gray);
`;

const Grid = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
`;

const InputStyledText = styled(Text)`
  padding: 12px 10px;
  border-radius: 6px;
  background-color: var(--color-snow-white);
  color: var(--color-dark-gray);
  min-height: 42px;
  font-weight: 400;
`;

const PaymentDueDateFields = ({ invoiceDate, view }) => {
  const orgsPaymentTermsDays = useRecoilValue(orgPaymentTerms);
  const dueDate = useMemo(
    () =>
      dayjs(invoiceDate).add(orgsPaymentTermsDays, "day").format("MM/DD/YYYY"),
    [invoiceDate, orgsPaymentTermsDays]
  );
  return (
    <ConditionalWrapper
      condition={view !== "customer"}
      wrapper={(children) => <Grid>{children}</Grid>}
    >
      <Flex column>
        <Label>Payment Terms</Label>
        <InputStyledText m="4px 0 20px 0">
          {orgsPaymentTermsDays}
        </InputStyledText>
      </Flex>
      <Flex column>
        <Label>Payment Due Date{view === "customer" && " (optional)"}</Label>
        <InputStyledText m="4px 0 20px 0">
          {invoiceDate && dueDate}
        </InputStyledText>
      </Flex>
    </ConditionalWrapper>
  );
};

export default PaymentDueDateFields;
